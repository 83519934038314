/* @flow */

import styled from "styled-components";

export const Sidebar = styled.div`
  box-sizing: border-box;
  width: 25%;
  padding: 5px 0;
  background: #374b7d;
  color: #fff;
  border-right: 1px solid #e0dfdf;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    color: #fff;
    padding: 0 15px;
  }

  & a {
    color: #fff !important;
    padding: 0;
    color: inherit;
    text-decoration: none;
    h2,
    * {
      color: #fff !important;
    }
  }
`;

export const CenterPage = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MainArea = styled.div`
  box-sizing: border-box;
  width: 75%;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #1c3a78;
  overflow: auto;
  padding: 10px;
`;

export const SidebarScroll = styled.div`
  max-height: 50%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #516cac;
  }

  & * {
    direction: ltr;
  }
`;

export const SidebarBottomItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin-top: auto;
`;

export const SidebarItem = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  padding: 6px 25px;
  margin: 2px 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => (props.active ? "#596693" : "transparent")};

  &:hover {
    background: ${(props) => (props.main ? "transparent" : "#596bac")};
  }

  ${(props) =>
    props.indent
      ? `
    & .fa {
      margin-right: 15px;
      margin-left: 4px;
      color: #bdc3ce;
  }`
      : ""}

  ${(props) =>
    props.main
      ? `
    & h2 {
      margin: 0;
    }
    & .fa {
      margin-right: 15px;
    }
  `
      : ``}
`;

export const Status = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  width: ${(props) => (props.small ? "20px" : props.smaller ? "15px" : "40px")};
  height: ${(props) =>
    props.small ? "20px" : props.smaller ? "15px" : "40px"};
  border-radius: 40px;
  background: ${(props) => (props.status ? "#1acc31" : "#d92716")};
  margin-right: 15px;
`;

export const StatusTable = styled.div`
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
`;

export const ColorBtn = styled.div`
  width: 30px;
  height: 30px;
  background: ${(props) => props.color};
  border: 2px solid #eee;
`;

export const BtnLink = styled.a`
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 5px 10px;
  margin: 5px 1px;
  border-radius: 5px;
  background: #17ad2a;
  border: 1px solid #52d563;
  box-shadow: 0 2px 0 #0d731a;
  font-size: 20px;
  line-height: 22px;
  cursor: pointer;
  color: #fff !important;
  text-align: center;
  text-shadow: 0 -1px #0d731a;

  & .fa {
    margin-right: ${(props) => (props.icon ? "0px" : "5px")};
    font-size: 22px;
  }

  &:active {
    top: 1px;
    box-shadow: 0 1px 0 #0d731a;
  }
`;

export const PopUp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const PopUpBody = styled.div`
  box-sizing: border-box;
  width: 70%;
  max-height: 70%;
  overflow: auto;
  padding: 5px 10px;
  margin: 5px;
  margin-left: 15%;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d4cfcf;
  box-shadow: 0 2px 0 #8e8e8e;
`;

export const ErrorTxt = styled.span`
  color: rgb(242, 59, 11) !important;
  * {
    color: rgb(242, 59, 11) !important;
  }
`;

export const StatusTxt = styled.h4`
  display: inline-block;
  margin: 20px;
  color: ${(props) => (props.error ? "#a02c0e" : "#1ed160")};
`;

export const Breadcrumb = styled.div`
  display: inline-block;
  text-align: center;
  color: #399fb6;

  & h4 {
    font-size: 12px;
    color: #a2a2a2;
    margin: 5px 0;
  }
`;

export const Alert = styled.div`
  padding: 15px 20px;
  background: ${(props) =>
    ({ error: "#f77756", success: "#73f0a1" }[props.type])};
  color: ${(props) =>
    ({ error: "#a02c0e", success: "#1ed160" }[props.type])} !important;
  * {
    color: ${(props) =>
      ({ error: "#a02c0e", success: "#1ed160" }[props.type])} !important;
  }
`;

export const ClickableTxt = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const SelectIds = styled.div`
  max-height: 100%;
  box-sizing: border-box;
  overflow: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SelectId = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => (props.indent ? "margin-left: 10px;" : "")}

  & input {
    margin-right: 5px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
  th {
    background: #374b7d;
    color: #fff;
    padding: 10px;
  }
  td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
