/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { CREATE_USERS_PREFIX } from '../../Data/constants/actions'
import { getLang, getUI, getCanAdmin, getLibraries } from '../../Data/selectors'
import { startCreateUser } from '../../Data/actionCreators'

import { Container, Body, Footer } from '../StyledComponents/Page'
import { MainArea } from '../StyledComponents/Main'
import MainSidebar from '../Components/MainSidebar'
import StatusMessage from '../Components/StatusMessage'
import { FormGroup, LoginForm, Btn } from '../StyledComponents/Forms'
import { uiSet } from '../../Data/actionCreators'
import SelectInput from '../Components/Inputs/SelectInput'
import TextInput from '../Components/Inputs/TextInput'
import PasswordInput from '../Components/Inputs/PasswordInput'


export class UserCreate extends Component<any,any> {

  state = { obj: fromJS({ username: '', password: '', userType: 10 }) }

  onChange = (path: Array<string>) => (value: any) => {
    const { obj } = this.state
    this.setState({ obj: obj.setIn(path, value) })
  }

  submit = (e: Event) => {
    const { dispatch } = this.props
    const { obj } = this.state
    const username = obj.get('username')
    const password = obj.get('password')
    const userType = obj.get('userType')
    if (!!(username && password && userType)) {
      dispatch(startCreateUser(username, password, userType))
    } else {
      dispatch(uiSet({
        path: [CREATE_USERS_PREFIX],
        value: { type: 'error', message: 'error_invalid_entries' }
      }))
    }
  }

  render() {
    const { libraries, canAdmin, ui, lang } = this.props
    const { obj } = this.state

    return (
      <Container>
      <Body>
        <MainSidebar lang={lang} libraries={libraries} canAdmin={canAdmin}></MainSidebar>
        <MainArea>
            {ui.has(CREATE_USERS_PREFIX) ?
              <StatusMessage lang={lang} object={ui.get(CREATE_USERS_PREFIX)} /> : null}
            <LoginForm>
              <h1>{lang.createUserTitle}</h1>
              <FormGroup>
                <label htmlFor="name">{lang.username}</label>
                <TextInput type="text" id="name" path={['username']} obj={obj} onChange={this.onChange} />
              </FormGroup>
              <FormGroup>
                <label htmlFor="password">{lang.password} {lang.passwordNotice}</label>
                <PasswordInput id="password" path={['password']} obj={obj} onChange={this.onChange} />
              </FormGroup>
              <FormGroup>
                <label htmlFor="userType">{lang.userType}</label>
                <SelectInput id="userType" optionCollection={true} options={lang.userTypesArr} path={['userType']} obj={obj} onChange={this.onChange} />
              </FormGroup>
              <Btn onClick={this.submit}>{lang.create}</Btn>
            </LoginForm>
        </MainArea>
      </Body>
      <Footer />
      </Container>
    )
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(UserCreate)
