/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import FA from "react-fontawesome";
import { fromJS, Map } from "immutable";
import { Prompt } from "react-router-dom";
import {
  getUI,
  getLibraries,
  getSettings,
  getLanguages,
  getLang,
  getSelfCheck,
  getLibrary,
  getCanAdmin,
} from "../../Data/selectors";
import { SAVE_SETTINGS_PREFIX } from "../../Data/constants/actions";
import StatusMessage from "../Components/StatusMessage";

import {
  setSetting,
  startGetSettings,
  startSaveSettings,
  startGetLanguages,
} from "../../Data/actionCreators";
import { Container, Body, Footer } from "../StyledComponents/Page";
import {
  MainArea,
  PopUp,
  PopUpBody,
  CenterPage,
  ErrorTxt,
  StatusTxt,
  Breadcrumb,
} from "../StyledComponents/Main";
import { Row, Col, ColL } from "../StyledComponents/Grid";
import { Btn } from "../StyledComponents/Forms";
import Radio from "../Components/Inputs/Radio";
import { Tree } from "antd";

import SettingsSidebar from "../Components/SettingsSidebar";
import LMS from "../Components/Settings/LMS";
import Design from "../Components/Settings/Design";
import ChipPin from "../Components/Settings/ChipPin";
import EMDevice from "../Components/Settings/EMDevice";
import Money from "../Components/Settings/Money";
import Timing from "../Components/Settings/Timing";
import Sorting from "../Components/Settings/Sorting";
import UserCard from "../Components/Settings/UserCard";
import Constants from "../Components/Settings/Constants";
import Receipts from "../Components/Settings/Receipts";

const { TreeNode } = Tree;

export class Settings extends Component<any, any> {
  state = {
    saveChoices: fromJS({ saveOption: "ws" }),
    savePopupOpen: false,
    importPopupOpen: false,
    dirty: false,
  };

  componentWillMount() {
    const { match, dispatch } = this.props;
    const id = match.params.id;
    this.setState({ dirty: false });
    dispatch(startGetSettings(id));
    dispatch(startGetLanguages());
  }

  componentWillReceiveProps(nextProps) {
    try {
      const { dispatch } = this.props;
      const oldTab = this.props.match.params.tab;
      const tab = nextProps.match.params.tab;
      if (oldTab !== tab) {
        this.setState({ dirty: false });
        dispatch(setSetting(["__updates"], new Map()));
      }
    } catch (e) {}
  }

  onChange = (path: Array<string>) => (value: any) => {
    const { dispatch } = this.props;
    this.setState({ dirty: true });
    dispatch(setSetting(path, value));
  };

  onSaveChange = (path: Array<string>) => (value: any) => {
    const { saveChoices } = this.state;
    const newSaveChoices = saveChoices.setIn(path, value);
    this.setState({ saveChoices: newSaveChoices });
  };

  openSavePopup = () => this.setState({ savePopupOpen: true });
  closeSavePopup = () => this.setState({ savePopupOpen: false });
  openImportPopup = () => this.setState({ importPopupOpen: true });
  closeImportPopup = () => this.setState({ importPopupOpen: false });

  saveSettings = () => {
    const { match, settings, dispatch } = this.props;
    const id = match.params.id;
    const tab = match.params.tab || "lms";
    const saveChoices = this.state.saveChoices.toJS();
    const { selfChecks = [] } = saveChoices;
    const newSelfChecks = selfChecks.filter((e) => !~e.indexOf("ignore-"));
    const newSaveChoices = { ...saveChoices, selfChecks: newSelfChecks };
    const subSettings = settings.get(tab, new Map());
    const __updates = settings.get("__updates", new Map());
    if (__updates === false) {
      const payloadSettings = new Map().set(tab, subSettings);
      dispatch(startSaveSettings(id, payloadSettings, newSaveChoices));
    } else {
      const toSave = __updates
        .filter((e) => e)
        .keySeq()
        .map((e) => fromJS(e.split(/:/g)))
        .flatten()
        .toJS();
      const settingsToSave = subSettings.filter((e, k) => toSave.includes(k));
      const payloadSettings = new Map().set(tab, settingsToSave);
      dispatch(startSaveSettings(id, payloadSettings, newSaveChoices));
      dispatch(setSetting(["__updates"], new Map()));
    }
    this.setState({ dirty: false });
    this.closeSavePopup();
  };

  onSelectTree = (checkedKeys) => {
    const { saveChoices } = this.state;
    const newSaveChoices = saveChoices.set("selfChecks", checkedKeys);
    this.setState({ saveChoices: newSaveChoices });
  };

  exportJson = () => {
    const { settings } = this.props;
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify({ settings: settings.toJS() }));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "settings.json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  saveImportedSettings = () => {
    const { match, dispatch } = this.props;
    const id = match.params.id;
    if (this.importInput) {
      let file = this.importInput.files[0];
      let fr = new FileReader();
      fr.onload = (e) => {
        const txt = e.target.result;
        try {
          const json = JSON.parse(txt);
          if (!json.settings) throw Error();
          dispatch(
            startSaveSettings(id, json.settings, {
              saveOption: "ws",
              selfChecks: [],
            })
          );
          this.closeImportPopup();
        } catch (e) {
          window.alert("Invalid file type");
        }
      };
      fr.readAsText(file);
    }
  };

  reload = () => window.location.reload();

  render() {
    const {
      ui,
      canAdmin,
      libraries,
      settings,
      languages,
      lang,
      match,
    } = this.props;
    const { dirty } = this.state;
    const id = match.params.id;
    const tab = match.params.tab || "lms";
    const selfCheck = getSelfCheck(libraries, id);
    const library =
      selfCheck && getLibrary(libraries, selfCheck.get("library_id"));

    const expanded = libraries
      .valueSeq()
      .map((e, i) =>
        e.get("id") === selfCheck.get("library_id") ? `ignore-${i}` : null
      )
      .filter((e) => e)
      .toJS();

    return ui.get("connectionError") ? (
      <Container>
        <CenterPage>
          <ErrorTxt>
            <h1>{lang.connectionError}</h1>
          </ErrorTxt>
          <Btn onClick={this.reload}>
            <FA name="refresh" /> {lang.retry}
          </Btn>
        </CenterPage>
      </Container>
    ) : ui.get("loadingSettings") ? (
      <Container>
        <CenterPage>
          <StatusTxt>
            <h1>Loading settings...</h1>
          </StatusTxt>
        </CenterPage>
      </Container>
    ) : (
      <Container>
        <Prompt
          when={dirty}
          message={() =>
            `You have unsaved changes, are you sure you want to discard them?`
          }
        />
        <Body>
          <SettingsSidebar lang={lang} id={id} tab={tab} dirty={dirty} />
          <MainArea>
            {ui.has(SAVE_SETTINGS_PREFIX) ? (
              <StatusMessage
                lang={lang}
                object={ui.get(SAVE_SETTINGS_PREFIX)}
              />
            ) : null}
            <Row>
              <Col size={4}>
                {canAdmin && (
                  <Btn
                    onClick={this.openImportPopup}
                    style={{ marginRight: 10, marginLeft: "auto" }}
                  >
                    <FA name="upload" /> {lang.import}
                  </Btn>
                )}
                {canAdmin && (
                  <Btn onClick={this.exportJson} style={{ marginRight: 10 }}>
                    <FA name="download" /> {lang.export}
                  </Btn>
                )}
                <Btn onClick={this.openSavePopup}>
                  <FA name="save" /> {lang.save}
                </Btn>
              </Col>
            </Row>
            <Row>
              <Col size={2} bold={true} center={true}>
                <Breadcrumb>
                  {selfCheck ? (
                    <React.Fragment>
                      {library.get("name")} - {selfCheck.get("name")}
                      <h4>{selfCheck.get("id")}</h4>
                    </React.Fragment>
                  ) : null}
                </Breadcrumb>
              </Col>
            </Row>
            {tab === "lms" ? (
              <LMS lang={lang} settings={settings} onChange={this.onChange} />
            ) : tab === "design" ? (
              <Design
                lang={lang}
                settings={settings}
                onChange={this.onChange}
              />
            ) : tab === "chippin" ? (
              <ChipPin
                lang={lang}
                settings={settings}
                onChange={this.onChange}
              />
            ) : tab === "EMDevice" ? (
              <EMDevice
                lang={lang}
                settings={settings}
                onChange={this.onChange}
              />
            ) : tab === "money" ? (
              <Money lang={lang} settings={settings} onChange={this.onChange} />
            ) : tab === "timing" ? (
              <Timing
                lang={lang}
                settings={settings}
                onChange={this.onChange}
              />
            ) : tab === "sorting" ? (
              <Sorting
                lang={lang}
                settings={settings}
                onChange={this.onChange}
              />
            ) : tab === "userCard" ? (
              <UserCard
                lang={lang}
                settings={settings}
                onChange={this.onChange}
              />
            ) : tab === "constants" ? (
              <Constants
                lang={lang}
                languages={languages}
                settings={settings}
                onChange={this.onChange}
              />
            ) : tab === "receipts" ? (
              <Receipts
                lang={lang}
                languages={languages}
                settings={settings}
                onChange={this.onChange}
              />
            ) : null}
          </MainArea>
          {this.state.savePopupOpen ? (
            <PopUp>
              <PopUpBody>
                <Row tiny={true}>
                  <ColL size={1}>
                    <h2 style={{ margin: 0 }}>{lang.confirmSave}</h2>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={0}>
                    <Radio
                      style={{ margin: "auto" }}
                      name="saveOption"
                      id="saveGlobal"
                      value="global"
                      path={["saveOption"]}
                      obj={this.state.saveChoices}
                      onChange={this.onSaveChange}
                    />
                  </ColL>
                  <ColL size={1}>
                    <label htmlFor="saveGlobal">{lang.saveGlobal}</label>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={0}>
                    <Radio
                      style={{ margin: "auto" }}
                      name="saveOption"
                      id="saveLibrary"
                      value="library"
                      path={["saveOption"]}
                      obj={this.state.saveChoices}
                      onChange={this.onSaveChange}
                    />
                  </ColL>
                  <ColL size={1}>
                    <label htmlFor="saveLibrary">{lang.saveLibrary}</label>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={0}>
                    <Radio
                      style={{ margin: "auto" }}
                      name="saveOption"
                      id="savePick"
                      value="pws"
                      path={["saveOption"]}
                      obj={this.state.saveChoices}
                      onChange={this.onSaveChange}
                    />
                  </ColL>
                  <ColL size={1}>
                    <label htmlFor="savePick">{lang.savePick}</label>
                  </ColL>
                </Row>
                {this.state.saveChoices.get("saveOption") === "pws" && (
                  <Row tiny={true}>
                    <Tree
                      checkable
                      defaultCheckedKeys={[id]}
                      defaultExpandedKeys={expanded}
                      onCheck={this.onSelectTree}
                    >
                      {libraries.valueSeq().map((library, i) => (
                        <TreeNode
                          title={library.get("name")}
                          key={`ignore-${i}`}
                        >
                          {library.get("selfChecks").map((selfCheck) => (
                            <TreeNode
                              title={selfCheck.get("name")}
                              key={selfCheck.get("id")}
                              disabled={selfCheck.get("id") === id}
                            />
                          ))}
                        </TreeNode>
                      ))}
                    </Tree>
                  </Row>
                )}

                <Row tiny={true}>
                  <ColL size={0}>
                    <Radio
                      style={{ margin: "auto" }}
                      name="saveOption"
                      id="saveWS"
                      value="ws"
                      path={["saveOption"]}
                      obj={this.state.saveChoices}
                      onChange={this.onSaveChange}
                    />
                  </ColL>
                  <ColL size={1}>
                    <label htmlFor="saveWS">{lang.saveWS}</label>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={2}>
                    <Btn
                      onClick={this.closeSavePopup}
                      style={{ marginRight: 5 }}
                    >
                      <FA name="times" /> {lang.cancel}
                    </Btn>
                    <Btn onClick={this.saveSettings}>
                      <FA name="save" /> {lang.save}
                    </Btn>
                  </ColL>
                  <Col size={2} />
                </Row>
              </PopUpBody>
            </PopUp>
          ) : null}
          {this.state.importPopupOpen ? (
            <PopUp>
              <PopUpBody>
                <Row tiny={true}>
                  <ColL size={1}>
                    <h2 style={{ margin: 0 }}>{lang.importSettings}</h2>
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={8}>
                    <h4>{lang.warningImport}</h4>
                    <input type="file" ref={(el) => (this.importInput = el)} />
                  </ColL>
                </Row>
                <Row tiny={true}>
                  <ColL size={2}>
                    <Btn
                      onClick={this.closeImportPopup}
                      style={{ marginRight: 5 }}
                    >
                      <FA name="times" /> {lang.cancel}
                    </Btn>
                    <Btn onClick={this.saveImportedSettings}>
                      <FA name="save" /> {lang.save}
                    </Btn>
                  </ColL>
                  <Col size={2} />
                </Row>
              </PopUpBody>
            </PopUp>
          ) : null}
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  ui: getUI(state),
  canAdmin: getCanAdmin(state),
  libraries: getLibraries(state),
  settings: getSettings(state),
  languages: getLanguages(state),
  lang: getLang(state),
}))(Settings);
