/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import FA from "react-fontawesome";

import {
  getLang,
  getMisc,
  getCanAdmin,
  getUI,
  getLibraries,
} from "../../Data/selectors";
import { SAVE_GEN_SETTINGS_PREFIX } from "../../Data/constants/actions";
import {
  startLogout,
  startGetGenSettings,
  startSaveGenSettings,
} from "../../Data/actionCreators";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { Btn } from "../StyledComponents/Forms";
import { Row, Col } from "../StyledComponents/Grid";
import { MainArea, StatusTable } from "../StyledComponents/Main";
import MainSidebar from "../Components/MainSidebar";
import StatusMessage from "../Components/StatusMessage";
import TextInput from "../Components/Inputs/TextInput";
import CheckBox from "../Components/Inputs/CheckBox";

export class Notifications extends Component<any, any> {
  state = { obj: this.props.genSettings };

  componentWillReceiveProps(newProps) {
    const { obj } = this.state;
    this.setState({ obj: newProps.genSettings.merge(obj) });
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(startGetGenSettings());
  }

  onChange = (path: Array<string>) => (value: any) => {
    const { obj } = this.state;
    this.setState({ obj: obj.setIn(path, value) });
  };

  submit = (e: Event) => {
    const { dispatch } = this.props;
    const { obj } = this.state;
    dispatch(startSaveGenSettings(obj));
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  render() {
    const { libraries, ui, canAdmin, lang } = this.props;
    const { obj } = this.state;

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            id={null}
            canAdmin={canAdmin}
            logout={this.logout}
          ></MainSidebar>
          <MainArea>
            {ui.has(SAVE_GEN_SETTINGS_PREFIX) ? (
              <StatusMessage
                lang={lang}
                object={ui.get(SAVE_GEN_SETTINGS_PREFIX)}
              />
            ) : null}
            <Row>
              <Col size={8} bold={true} center={true}>
                <h1 style={{ textAlign: "center" }}>{lang.notifications}</h1>
              </Col>
              <Col size={2} bold={true} center={true}>
                <Btn onClick={this.submit}>
                  <FA name="save" />
                  {lang.save}
                </Btn>
              </Col>
            </Row>
            <Row bb={true}>
              <Col size={2} bold={true} center={true}></Col>
              <Col size={2} bold={true} center={true}>
                {lang.onOff}
              </Col>
              <Col size={4} bold={true} center={true}>
                {lang.emails}
              </Col>
            </Row>
            <StatusTable>
              <Row bb={true}>
                <Col size={2} center={true}>
                  {lang.kioskOffline}
                </Col>
                <Col size={2} center={true}>
                  <CheckBox
                    style={{ marginRight: 10 }}
                    path={["kioskOffline"]}
                    obj={obj}
                    onChange={this.onChange}
                  />
                </Col>
                <Col size={4} center={true}>
                  <TextInput
                    type="text"
                    path={["kioskOfflineEmail"]}
                    obj={obj}
                    onChange={this.onChange}
                  />
                </Col>
              </Row>
              <Row bb={true}>
                <Col size={2} center={true}>
                  {lang.receiptPaperLow}
                </Col>
                <Col size={2} center={true}>
                  <CheckBox
                    style={{ marginRight: 10 }}
                    path={["receiptPaperLow"]}
                    obj={obj}
                    onChange={this.onChange}
                  />
                </Col>
                <Col size={4} center={true}>
                  <TextInput
                    type="text"
                    path={["receiptPaperLowEmail"]}
                    obj={obj}
                    onChange={this.onChange}
                  />
                </Col>
              </Row>
              <Row bb={true}>
                <Col size={2} center={true}>
                  {lang.coinLow}
                </Col>
                <Col size={2} center={true}>
                  <CheckBox
                    style={{ marginRight: 10 }}
                    path={["coinLow"]}
                    obj={obj}
                    onChange={this.onChange}
                  />
                </Col>
                <Col size={4} center={true}>
                  <TextInput
                    type="text"
                    path={["coinLowEmail"]}
                    obj={obj}
                    onChange={this.onChange}
                  />
                </Col>
              </Row>
              <Row bb={true}>
                <Col size={2} center={true}>
                  {lang.kioskCnOffline}
                </Col>
                <Col size={2} center={true}>
                  <CheckBox
                    style={{ marginRight: 10 }}
                    path={["kioskCnOffline"]}
                    obj={obj}
                    onChange={this.onChange}
                  />
                </Col>
                <Col size={4} center={true}>
                  <TextInput
                    type="text"
                    path={["kioskCnOfflineEmail"]}
                    obj={obj}
                    onChange={this.onChange}
                  />
                </Col>
              </Row>
            </StatusTable>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  genSettings: getMisc(state, "genSettings") || new Map(),
  libraries: getLibraries(state),
  lang: getLang(state),
  ui: getUI(state),
}))(Notifications);
