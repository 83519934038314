/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import FA from 'react-fontawesome'

import { getLang, getUsers, getCanAdmin, getUI, getLibraries } from '../../Data/selectors'
import { DELETE_SC_PREFIX } from '../../Data/constants/actions'
import { startGetUsers, startLogout, startDeleteUser } from '../../Data/actionCreators'

import { Container, Body, Footer } from '../StyledComponents/Page'
import { Btn } from '../StyledComponents/Forms'
import { Row, Col } from '../StyledComponents/Grid'
import { MainArea, StatusTable } from '../StyledComponents/Main'
import MainSidebar from '../Components/MainSidebar'
import StatusMessage from '../Components/StatusMessage'



export class Users extends Component<any,any> {

  componentWillMount() {
    const { dispatch } = this.props
    dispatch(startGetUsers())
  }

  delete = (id: any) => () => {
    const { lang, dispatch } = this.props
    const res = window.confirm(lang.confirmDeleteUser)
    if (res) dispatch(startDeleteUser(id))
  }

  update = (id: any) => () => {
    const { dispatch } = this.props
    dispatch(startDeleteUser(id))
  }

  logout = () => {
    const { dispatch } = this.props
    dispatch(startLogout())
  }

  render() {
    const { libraries, users, ui, canAdmin, lang } = this.props

    return (
      <Container>
      <Body>
        <MainSidebar lang={lang} libraries={libraries} id={null}
          canAdmin={canAdmin} logout={this.logout}></MainSidebar>
        <MainArea>
          {ui.has(DELETE_SC_PREFIX) ?
            <StatusMessage lang={lang} object={ui.get(DELETE_SC_PREFIX)} /> : null}
          <Row>
            <Col size={8} bold={true} center={true}>
              <h1 style={{textAlign: 'center'}}>{lang.users}</h1>
            </Col>
            <Col size={2} bold={true} center={true}>
              <Link to={'/users/create'}><Btn><FA name="plus" />{lang.create}</Btn></Link>
            </Col>
          </Row>
          <Row bb={true}>
            <Col size={2} bold={true} center={true}>{lang.username}</Col>
            <Col size={2} bold={true} center={true}>{lang.userType}</Col>
            <Col size={4} bold={true} center={true}></Col>
          </Row>
          <StatusTable>
          { users.map(user => {
            return (
              <Row bb={true} key={user.get('id')}>
                <Col size={2} center={true}>{user.get('username')}</Col>
                <Col size={2} center={true}>{lang.userTypes[user.get('user_type')]}</Col>
                <Col size={4} center={true}>
                  <Link to={'/users/' + user.get('id') + '/edit'}>
                    <Btn icon={1} tiny={1}><FA name="edit" /></Btn>
                  </Link>
                  <Btn icon={1} danger={1} tiny={1} style={{marginLeft: 10}} onClick={this.delete(user.get('id'))}>
                    <FA name="remove" /></Btn>
                </Col>
              </Row>)
          })}
          </StatusTable>
        </MainArea>
      </Body>
      <Footer />
      </Container>
    )
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  users: getUsers(state),
  libraries: getLibraries(state),
  lang: getLang(state),
  ui: getUI(state),
}))(Users)
