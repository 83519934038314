/* @flow */

import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

const options = {
  responsive: true,
  tooltips: { mode: "label" },
  elements: { line: { fill: false } },
  legend: { display: false },
  scales: {
    xAxes: [{ display: true, gridLines: { display: false } }],
    yAxes: [
      {
        display: true,
        gridLines: { display: false },
        ticks: { beginAtZero: true },
      },
    ],
  },
};

const getData = (lang, issues, returns) => ({
  labels: [lang.issues, lang.returns],
  datasets: [
    {
      backgroundColor: ["#1e2f8b", "#b92424"],
      data: [issues, returns],
    },
  ],
});

export default class BarStatsChart extends Component<any, any> {
  render() {
    const { lang, issues, returns } = this.props;

    return (
      <Bar
        data={getData(lang, issues, returns)}
        width={120}
        height={80}
        options={options}
      />
    );
  }
}
