/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CREATE_SC_PREFIX } from '../../Data/constants/actions'
import { getLang, getUI, getLibraries } from '../../Data/selectors'

import { Container, Body, Footer } from '../StyledComponents/Page'
import { MainArea } from '../StyledComponents/Main'
import MainSidebar from '../Components/MainSidebar'
import StatusMessage from '../Components/StatusMessage'
import { FormGroup, LoginForm, Btn } from '../StyledComponents/Forms'
import { createSelfCheck } from '../../Data/actionCreators'


export class SelfCheckCreate extends Component<any,any> {

  state = { name: '' }

  change = (key: string) => (e: Event) => {
    this.setState({ [key]: e.target.value })
  }

  submit = (e: Event) => {
    const { dispatch, match } = this.props
    const { id } = match.params
    const { name } = this.state
    dispatch(createSelfCheck(id, name))
  }

  render() {
    const { libraries, ui, lang } = this.props
    const { name } = this.state

    return (
      <Container>
      <Body>
        <MainSidebar lang={lang} libraries={libraries}></MainSidebar>
        <MainArea>
            {ui.has(CREATE_SC_PREFIX) ?
              <StatusMessage lang={lang} object={ui.get(CREATE_SC_PREFIX)} /> : null}
            <LoginForm>
              <h1>{lang.createSelfCheckTitle}</h1>
              <FormGroup>
                <label htmlFor="name">{lang.selfCheckName}</label>
                <input type="text" id="name" value={name} onChange={this.change('name')} />
              </FormGroup>
              <Btn onClick={this.submit}>{lang.create}</Btn>
            </LoginForm>
        </MainArea>
      </Body>
      <Footer />
      </Container>
    )
  }
}

export default connect((state: State) => ({
  ui: getUI(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(SelfCheckCreate)
