import { takeLatest, all, put } from "redux-saga/effects";
import { doRequest } from "../actionCreators";
import {
  UI_SET,
  SUCCESS_MSG,
  SUCCESS_SUFFIX,
  ERROR_SUFFIX,
  LOGIN_PREFIX,
  LOGIN_START,
  GET_LIBS_PREFIX,
  GET_LIBS_START,
  CREATE_LIB_PREFIX,
  CREATE_LIB_START,
  CREATE_SC_PREFIX,
  CREATE_SC_START,
  GET_SETTINGS_PREFIX,
  GET_SETTINGS_START,
  SAVE_SETTINGS_PREFIX,
  SAVE_SETTINGS_START,
  GET_LANGUAGES_PREFIX,
  GET_LANGUAGES_START,
  SAVE_LANGUAGES_PREFIX,
  SAVE_LANGUAGES_START,
  DELETE_LIB_PREFIX,
  DELETE_LIB_START,
  DELETE_SC_PREFIX,
  DELETE_SC_START,
  GET_THEMES_PREFIX,
  GET_THEMES_START,
  SAVE_THEMES_PREFIX,
  SAVE_THEMES_START,
  GET_INFO_PREFIX,
  GET_INFO_START,
  DO_UPDATE_PREFIX,
  DO_UPDATE_START,
  GET_USERS_PREFIX,
  GET_USERS_START,
  CREATE_USERS_PREFIX,
  CREATE_USERS_START,
  UPDATE_USERS_PREFIX,
  UPDATE_USERS_START,
  DELETE_USERS_PREFIX,
  DELETE_USERS_START,
  GET_COUNTS_PREFIX,
  GET_COUNTS_START,
  EXPORT_COUNTS_PREFIX,
  EXPORT_COUNTS_START,
  GET_PAYMENTS_PREFIX,
  GET_PAYMENTS_START,
  GET_REPORT_PREFIX,
  GET_REPORT_START,
  SAVE_DATES_PREFIX,
  SAVE_DATES_START,
  EXPORT_PAYMENTS_PREFIX,
  EXPORT_PAYMENTS_START,
  SEND_PAYMENTS_PREFIX,
  SEND_PAYMENTS_START,
  GET_MAIN_STATS_PREFIX,
  GET_MAIN_STATS_START,
  GET_GEN_SETTINGS_PREFIX,
  GET_GEN_SETTINGS_START,
  SAVE_GEN_SETTINGS_PREFIX,
  SAVE_GEN_SETTINGS_START,
  EDIT_SC_PREFIX,
  EDIT_SC_START,
  EDIT_LIB_START,
  EDIT_LIB_PREFIX,
  GET_LIVE_UPDATES_PREFIX,
  GET_LIVE_UPDATES_START,
} from "../constants/actions";

const req = (method, token) => (prefix: string, route, postData: Object = {}) =>
  doRequest({
    route,
    method,
    token,
    postData,
    successCallback: function* (data) {
      yield put({
        type: UI_SET,
        payload: {
          path: [prefix],
          value: { type: "success", message: prefix + SUCCESS_MSG },
        },
      });
      yield put({ type: prefix + SUCCESS_SUFFIX, payload: data });
    },
    errorCallback: function* (error) {
      yield put({
        type: UI_SET,
        payload: {
          path: [prefix],
          value: { type: "error", message: error },
        },
      });
    },
  });

const authGet = (prefix: string, route) =>
  doRequest({
    route,
    method: "get",
    token: true,
    successCallback: (data) =>
      put({ type: prefix + SUCCESS_SUFFIX, payload: data }),
    errorCallback: (error) =>
      put({ type: prefix + ERROR_SUFFIX, payload: { error } }),
  });

const normalPost = req("post", false);
const authPost = req("post", true);
const authDelete = req("delete", true);
const authPut = req("put", true);

function* login(action) {
  yield put(normalPost(LOGIN_PREFIX, "auth/login", action.payload));
}

function* getLibraries(action) {
  yield put(authGet(GET_LIBS_PREFIX, "api/libraries"));
}

function* createLibrary(action) {
  yield put(authPost(CREATE_LIB_PREFIX, "api/libraries", action.payload));
}

function* editLibrary(action) {
  const { id } = action.payload;
  yield put(
    authPost(
      EDIT_LIB_PREFIX,
      ["api/libraries", id, "edit"].join("/"),
      action.payload
    )
  );
}

function* createSelfCheck(action) {
  const { id } = action.payload;
  yield put(
    authPost(
      CREATE_SC_PREFIX,
      ["api/libraries", id, "selfChecks"].join("/"),
      action.payload
    )
  );
}

function* editSelfCheck(action) {
  const { id } = action.payload;
  yield put(
    authPost(
      EDIT_SC_PREFIX,
      ["api/selfChecks", id, "edit"].join("/"),
      action.payload
    )
  );
}

function* getGenSettings() {
  yield put(authGet(GET_GEN_SETTINGS_PREFIX, "api/general_settings"));
}

function* saveGenSettings(action) {
  yield put(
    authPost(SAVE_GEN_SETTINGS_PREFIX, "api/general_settings", action.payload)
  );
}

function* getSettings(action) {
  const { id } = action.payload;
  yield put(authGet(GET_SETTINGS_PREFIX, "api/settings/" + id));
}

function* saveSettings(action) {
  const { id } = action.payload;
  yield put(
    authPost(SAVE_SETTINGS_PREFIX, "api/settings/" + id, action.payload)
  );
}

function* getLanguages(action) {
  yield put(authGet(GET_LANGUAGES_PREFIX, "api/languages"));
}

function* saveLanguages(action) {
  yield put(authPost(SAVE_LANGUAGES_PREFIX, "api/languages", action.payload));
}

function* deleteLibrary(action) {
  const { id } = action.payload;
  yield put(
    authDelete(DELETE_LIB_PREFIX, "api/libraries/" + id, action.payload)
  );
}

function* deleteSelfCheck(action) {
  const { id } = action.payload;
  yield put(
    authDelete(DELETE_SC_PREFIX, "api/selfChecks/" + id, action.payload)
  );
}

function* getThemes(action) {
  const { id } = action.payload;
  yield put(authGet(GET_THEMES_PREFIX, "api/themes/" + id));
}

function* saveThemes(action) {
  const { id } = action.payload;
  yield put(authPost(SAVE_THEMES_PREFIX, "api/themes/" + id, action.payload));
}

function* getInfo(action) {
  yield put(authGet(GET_INFO_PREFIX, "api/info"));
}

function* doUpdate(action) {
  yield put(authPost(DO_UPDATE_PREFIX, "api/update"));
}

function* getUsers(action) {
  yield put(authGet(GET_USERS_PREFIX, "api/users"));
}

function* createUsers(action) {
  yield put(authPost(CREATE_USERS_PREFIX, "api/users", action.payload));
}

function* updateUser(action) {
  const { id } = action.payload;
  yield put(authPut(UPDATE_USERS_PREFIX, "api/users/" + id, action.payload));
}

function* deleteUser(action) {
  const { id } = action.payload;
  yield put(authDelete(DELETE_USERS_PREFIX, "api/users/" + id, action.payload));
}

function* getCounts(action) {
  yield put(authPost(GET_COUNTS_PREFIX, "api/counts", action.payload));
}

function* exportCounts(action) {
  yield put(
    authPost(EXPORT_COUNTS_PREFIX, "api/export-counts", action.payload)
  );
}

function* getPayments(action) {
  yield put(authPost(GET_PAYMENTS_PREFIX, "api/payments", action.payload));
}

function* getReport(action) {
  yield put(authPost(GET_REPORT_PREFIX, "api/zreport", action.payload));
}

function* saveDates(action) {
  yield put(authPost(SAVE_DATES_PREFIX, "api/zreport-update", action.payload));
}

function* exportPayments(action) {
  yield put(
    authPost(EXPORT_PAYMENTS_PREFIX, "api/export-payments", action.payload)
  );
}

function* sendPayments(action) {
  yield put(
    authPost(SEND_PAYMENTS_PREFIX, "api/send-payments", action.payload)
  );
}

function* getMainStats() {
  yield put(authGet(GET_MAIN_STATS_PREFIX, "api/main-stats"));
}

function* getLiveUpdates() {
  yield put(authGet(GET_LIVE_UPDATES_PREFIX, "api/live-updates"));
}

function* saga() {
  yield all([
    takeLatest(LOGIN_START, login),
    takeLatest(GET_LIBS_START, getLibraries),
    takeLatest(GET_GEN_SETTINGS_START, getGenSettings),
    takeLatest(SAVE_GEN_SETTINGS_START, saveGenSettings),
    takeLatest(CREATE_LIB_START, createLibrary),
    takeLatest(EDIT_LIB_START, editLibrary),
    takeLatest(CREATE_SC_START, createSelfCheck),
    takeLatest(EDIT_SC_START, editSelfCheck),
    takeLatest(GET_SETTINGS_START, getSettings),
    takeLatest(SAVE_SETTINGS_START, saveSettings),
    takeLatest(GET_LANGUAGES_START, getLanguages),
    takeLatest(SAVE_LANGUAGES_START, saveLanguages),
    takeLatest(DELETE_LIB_START, deleteLibrary),
    takeLatest(DELETE_SC_START, deleteSelfCheck),
    takeLatest(GET_THEMES_START, getThemes),
    takeLatest(SAVE_THEMES_START, saveThemes),
    takeLatest(GET_INFO_START, getInfo),
    takeLatest(DO_UPDATE_START, doUpdate),
    takeLatest(GET_USERS_START, getUsers),
    takeLatest(CREATE_USERS_START, createUsers),
    takeLatest(UPDATE_USERS_START, updateUser),
    takeLatest(DELETE_USERS_START, deleteUser),
    takeLatest(GET_COUNTS_START, getCounts),
    takeLatest(EXPORT_COUNTS_START, exportCounts),
    takeLatest(SEND_PAYMENTS_START, sendPayments),
    takeLatest(GET_PAYMENTS_START, getPayments),
    takeLatest(GET_REPORT_START, getReport),
    takeLatest(SAVE_DATES_START, saveDates),
    takeLatest(EXPORT_PAYMENTS_START, exportPayments),
    takeLatest(GET_MAIN_STATS_START, getMainStats),
    takeLatest(GET_LIVE_UPDATES_START, getLiveUpdates),
  ]);
}

export default saga;
