/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import FA from "react-fontawesome";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import keys from "lodash/keys";

import {
  getLang,
  getCanAdmin,
  getUI,
  getCounts,
  getLibraries
} from "../../Data/selectors";
import {
  startGetCounts,
  startLogout,
  startExportCounts
} from "../../Data/actionCreators";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { Btn } from "../StyledComponents/Forms";
import { RowG, ColG } from "../StyledComponents/Grid";
import { SettingsTitle } from "../StyledComponents/Settings";
import { MainArea, SelectIds, SelectId } from "../StyledComponents/Main";
import DateInput from "../Components/Inputs/DateInput";
import CountsChart from "../Components/CountsChart";
import MainSidebar from "../Components/MainSidebar";

export class CombinedStats extends Component<any, any> {
  state = { range: {}, ids: {} };

  load = (range: Object = {}, ids: Object = {}) => {
    const { dispatch } = this.props;
    const _ids = keys(pickBy(ids, identity));
    dispatch(startGetCounts(_ids, range));
  };

  dateChange = (range: Object) => {
    const { ids } = this.state;
    const { from, to } = range;
    if (from && to) this.load(range, ids);
    this.setState({ range });
  };

  select = (id: any, library: any, isLib: boolean) => (e: Event) => {
    const { ids, range } = this.state;
    if (isLib) {
      const newIds = library.get("selfChecks").reduce((p, selfCheck) => {
        p[selfCheck.get("id")] = e.target.checked;
        return p;
      }, {});
      const newIds2 = { ...ids, ...newIds, [id]: e.target.checked };
      this.setState({ ids: newIds2 });
      this.load(range, newIds2);
    } else {
      const libId = library.get("id");
      const libCheckStatus = e.target.checked ? ids[libId] : false;
      const newIds = {
        ...ids,
        [id]: e.target.checked,
        [libId]: libCheckStatus
      };
      this.setState({ ids: newIds });
      this.load(range, newIds);
    }
  };

  export = () => {
    const { range, ids } = this.state;
    const { dispatch } = this.props;
    const _ids = keys(pickBy(ids, identity));
    dispatch(startExportCounts(_ids, range));
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  render() {
    const { ids } = this.state;
    const { libraries, counts, canAdmin, lang } = this.props;

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            canAdmin={canAdmin}
            logout={this.logout}
          />
          <MainArea>
            <SettingsTitle>{lang.combinedStats}</SettingsTitle>
            <RowG>
              <ColG size={3}>
                <SelectIds>
                  {libraries.valueSeq().map(library => (
                    <React.Fragment key={library.get("id")}>
                      <SelectId>
                        <input
                          type="checkbox"
                          onChange={this.select(
                            library.get("id"),
                            library,
                            true
                          )}
                          checked={!!ids[library.get("id")]}
                        />{" "}
                        <b>{library.get("name")}</b>
                      </SelectId>
                      {library.get("selfChecks").map(selfCheck => (
                        <SelectId indent={true} key={selfCheck.get("id")}>
                          <input
                            type="checkbox"
                            onChange={this.select(
                              selfCheck.get("id"),
                              library,
                              false
                            )}
                            checked={!!ids[selfCheck.get("id")]}
                          />{" "}
                          {selfCheck.get("name")}
                        </SelectId>
                      ))}
                    </React.Fragment>
                  ))}
                </SelectIds>
              </ColG>
              <ColG size={9} style={{ marginTop: 20 }}>
                <RowG>
                  <ColG size={7}>
                    <DateInput lang={lang} onChange={this.dateChange} />
                  </ColG>
                  <ColG size={2}>
                    <Btn icon={1} onClick={this.export}>
                      <FA name="download" /> {lang.export}
                    </Btn>
                  </ColG>
                </RowG>
                <RowG>
                  <CountsChart lang={lang} counts={counts.toJS()} />
                </RowG>
              </ColG>
            </RowG>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  counts: getCounts(state),
  libraries: getLibraries(state),
  lang: getLang(state)
}))(CombinedStats);
