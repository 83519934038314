export default {
  libLocations: "Library locations",
  login: "Login",
  device: "Device",
  status: "Status",
  statusMsg: "Status message",
  configuration: "Configuration",
  createLibTitle: "Create new library",
  editLibTitle: "Edit library",
  add: "Add",
  password: "Password",
  versionTxt: "Running version",
  newVersionTxt: "Found newer version",
  update: "Update",
  confirmUpdate:
    "Starting an update will interrupt the normal functioning of the system, only proceed after making sure no resource is needing it now",
  waitForUpdate:
    "The update is going on, please wait for a few minutes until the system is back",
  passwordNotice: "(Copy password when using generate)",
  create: "Create",
  createSelfCheckTitle: "Create new self-check",
  editSelfCheckTitle: "Edit self-check",
  createUserTitle: "Create new user",
  editUserTitle: "Edit user",
  libName: "Library name",
  selfCheckName: "Self-check name",
  connectionError: "Couldn't connect to server",
  logout: "Logout",
  retry: "Retry",
  save: "Save",
  edit: "Edit",
  cancel: "Cancel",
  confirmSave: "Confirm saving",
  saveGlobal: "Save globally",
  saveLibrary: "Save across library",
  saveWS: "Save workstation only",
  savePick: "Save on selected workstations only",
  language: "Language",
  addConstant: "Add constant",
  addConstantsFromFile: "Add constants from file",
  cstName: "Constant name",
  cstValue: "Constant value",
  langs: "Languages",
  onOff: "On/Off",
  email: "Email",
  emails: "Emails",
  kioskOffline: "Kiosk Offline",
  receiptPaperLow: "Receipt paper low",
  coinLow: "Coin low",
  kioskCnOffline: "Kiosk connection to ManageIT offline",
  culture: "Culture",
  langName: "Language name",
  delete: "Delete",
  users: "Users",
  notifications: "Notifications",
  username: "Username",
  userType: "User type",
  friendlyName: "Friendly name",
  addLang: "Add language",
  saved: "Saved",
  saveFailed: "Save failed",
  councilPayments: "Council payments",
  libraryPayments: "Library payments",
  created_at: "Created At",
  cash: "Cash",
  change: "Change",
  card: "Card",
  dayTakings: "Day takings",
  weekTakings: "Weekly takings",
  totalTakings: "Total takings",
  monthTakings: "Month takings",
  last7Takings: "Last 7 days takings",
  last30Takings: "Last 30 days takings",
  customTakings: "Custom takings",
  cards: "Cards",
  changeGiven: "Change given",
  float: "Float",
  notes: "Notes",
  kioskId: "Kiosk ID",
  kioskName: "Kiosk name",
  totalTX: "Total number of transactions",
  cashPaidIn: "Cash paid in",
  cardPaidIn: "Card paid in",
  cashChangeGiven: "Cash change given",
  overallCash: "Overall cash balance",
  from: "From",
  to: "To",
  selectStartDate: "Select start date",
  selectLastDate: "Select last date",
  selected: (from, to) => `Selected from ${from} to ${to}`,
  issues: "Issues",
  returns: "Returns",
  renews: "Renews",
  failedIssues: "Failed issues",
  failedReturns: "Failed returns",
  failedRenews: "Failed renews",
  combinedStats: "Combined Statistics",
  export: "Export",
  import: "Import",
  date: "Date",
  help: "Help/Support",
  zreport: "Z-Report",
  combinedReport: "Combined report",
  load: "Load",
  print: "Print",
  saveDate: "Save date",
  updating: "Updating...",
  importSettings: "Import settings file",
  importTheme: "Import theme file",
  warningImport:
    "Please make sure you select the right file, imported changes will be merged with old settings, changes aren't reversible",
  expiringSoon: (date) =>
    `Your license is expiring on ${date} please contact D-Tech to renew your license before the software is locked`,

  confirmLogout: "Are you sure you want to logout ?",
  confirmDeleteSC:
    "Deleting this self-check will remove it permenently along with any settings, theme or stats related to it. This can't be undone, are you sure ?",
  confirmDeleteLib:
    "Deleting this library will also delete all self-checks permenently along with any settings, theme or stats related to them. This can't be undone, are you sure ?",
  confirmDeleteUser:
    "Deleting a user is permenent and can't be undone, if you are deleting an Admin make sure there is another Admin left",

  LOGIN_SUCCESS_MSG: "Logged-in successfully",
  LOGIN_FAIL_MSG: "Wrong login details",
  CREATE_LIB_SUCCESS_MSG: "Library created successfully",
  EDIT_LIB_SUCCESS_MSG: "Library edited successfully",
  CREATE_SC_SUCCESS_MSG: "Self-check created successfully",
  EDIT_SC_SUCCESS_MSG: "Self-check edited successfully",
  SAVE_SETTINGS_SUCCESS_MSG: "Settings saved successfully",
  SAVE_THEMES_SUCCESS_MSG: "Theme saved successfully",
  SAVE_LANGUAGES_SUCCESS_MSG: "Languages saved successfully",
  DELETE_SC_SUCCESS_MSG: "Self-check removed successfully",
  CREATE_USERS_SUCCESS_MSG: "User created successfully",
  UPDATE_USERS_SUCCESS_MSG: "User updated successfully",
  SAVE_DATES_SUCCESS_MSG: "Dates saved for ZReport",
  SAVE_GEN_SETTINGS_SUCCESS_MSG: "Setting updated successfully",
  SEND_PAYMENTS_SUCCESS_MSG: "Email sent successfully",

  error_maxSelfChecks: "Max self-checks allowed by license reached",
  error_serverError: "Server error, please retry",
  error_nologin: "Wrong login details",
  error_invalid_entries: "Invalid entries, check the fields",
  error_reservedUser: "Already used username, use another",
  error_failedToMail: "Failed to send mail",

  userTypes: {
    30: "Admin",
    20: "Staff control",
    10: "Viewer only",
  },

  userTypesArr: [
    { value: 30, name: "Admin" },
    { value: 20, name: "Staff control" },
    { value: 10, name: "Viewer only" },
  ],

  locales: [
    { value: "", name: "--- Select language ---" },
    { value: "af", name: "Afrikaans" },
    { value: "af-NA", name: "Afrikaans (Namibia)" },
    { value: "af-ZA", name: "Afrikaans (South Africa)" },
    { value: "agq", name: "Aghem" },
    { value: "agq-CM", name: "Aghem (Cameroon)" },
    { value: "ak", name: "Akan" },
    { value: "ak-GH", name: "Akan (Ghana)" },
    { value: "am", name: "Amharic" },
    { value: "am-ET", name: "Amharic (Ethiopia)" },
    { value: "ar", name: "Arabic" },
    { value: "ar-001", name: "Arabic (World)" },
    { value: "ar-AE", name: "Arabic (United Arab Emirates)" },
    { value: "ar-BH", name: "Arabic (Bahrain)" },
    { value: "ar-DJ", name: "Arabic (Djibouti)" },
    { value: "ar-DZ", name: "Arabic (Algeria)" },
    { value: "ar-EG", name: "Arabic (Egypt)" },
    { value: "ar-EH", name: "Arabic (Western Sahara)" },
    { value: "ar-ER", name: "Arabic (Eritrea)" },
    { value: "ar-IL", name: "Arabic (Israel)" },
    { value: "ar-IQ", name: "Arabic (Iraq)" },
    { value: "ar-JO", name: "Arabic (Jordan)" },
    { value: "ar-KM", name: "Arabic (Comoros)" },
    { value: "ar-KW", name: "Arabic (Kuwait)" },
    { value: "ar-LB", name: "Arabic (Lebanon)" },
    { value: "ar-LY", name: "Arabic (Libya)" },
    { value: "ar-MA", name: "Arabic (Morocco)" },
    { value: "ar-MR", name: "Arabic (Mauritania)" },
    { value: "ar-OM", name: "Arabic (Oman)" },
    { value: "ar-PS", name: "Arabic (Palestinian Territories)" },
    { value: "ar-QA", name: "Arabic (Qatar)" },
    { value: "ar-SA", name: "Arabic (Saudi Arabia)" },
    { value: "ar-SD", name: "Arabic (Sudan)" },
    { value: "ar-SO", name: "Arabic (Somalia)" },
    { value: "ar-SS", name: "Arabic (South Sudan)" },
    { value: "ar-SY", name: "Arabic (Syria)" },
    { value: "ar-TD", name: "Arabic (Chad)" },
    { value: "ar-TN", name: "Arabic (Tunisia)" },
    { value: "ar-YE", name: "Arabic (Yemen)" },
    { value: "as", name: "Assamese" },
    { value: "as-IN", name: "Assamese (India)" },
    { value: "asa", name: "Asu" },
    { value: "asa-TZ", name: "Asu (Tanzania)" },
    { value: "az", name: "Azerbaijani" },
    { value: "az-Cyrl", name: "Azerbaijani (Cyrillic)" },
    { value: "az-Cyrl-AZ", name: "Azerbaijani (Cyrillic, Azerbaijan)" },
    { value: "az-Latn", name: "Azerbaijani (Latin)" },
    { value: "az-Latn-AZ", name: "Azerbaijani (Latin, Azerbaijan)" },
    { value: "bas", name: "Basaa" },
    { value: "bas-CM", name: "Basaa (Cameroon)" },
    { value: "be", name: "Belarusian" },
    { value: "be-BY", name: "Belarusian (Belarus)" },
    { value: "bem", name: "Bemba" },
    { value: "bem-ZM", name: "Bemba (Zambia)" },
    { value: "bez", name: "Bena" },
    { value: "bez-TZ", name: "Bena (Tanzania)" },
    { value: "bg", name: "Bulgarian" },
    { value: "bg-BG", name: "Bulgarian (Bulgaria)" },
    { value: "bm", name: "Bambara" },
    { value: "bm-Latn", name: "Bambara (Latin)" },
    { value: "bm-Latn-ML", name: "Bambara (Latin, Mali)" },
    { value: "bn", name: "Bengali" },
    { value: "bn-BD", name: "Bengali (Bangladesh)" },
    { value: "bn-IN", name: "Bengali (India)" },
    { value: "bo", name: "Tibetan" },
    { value: "bo-CN", name: "Tibetan (China)" },
    { value: "bo-IN", name: "Tibetan (India)" },
    { value: "br", name: "Breton" },
    { value: "br-FR", name: "Breton (France)" },
    { value: "brx", name: "Bodo" },
    { value: "brx-IN", name: "Bodo (India)" },
    { value: "bs", name: "Bosnian" },
    { value: "bs-Cyrl", name: "Bosnian (Cyrillic)" },
    { value: "bs-Cyrl-BA", name: "Bosnian (Cyrillic, Bosnia & Herzegovina)" },
    { value: "bs-Latn", name: "Bosnian (Latin)" },
    { value: "bs-Latn-BA", name: "Bosnian (Latin, Bosnia & Herzegovina)" },
    { value: "ca", name: "Catalan" },
    { value: "ca-AD", name: "Catalan (Andorra)" },
    { value: "ca-ES", name: "Catalan (Spain)" },
    { value: "ca-FR", name: "Catalan (France)" },
    { value: "ca-IT", name: "Catalan (Italy)" },
    { value: "cgg", name: "Chiga" },
    { value: "cgg-UG", name: "Chiga (Uganda)" },
    { value: "chr", name: "Cherokee" },
    { value: "chr-US", name: "Cherokee (United States)" },
    { value: "ckb", name: "Central Kurdish" },
    { value: "ckb-IQ", name: "Central Kurdish (Iraq)" },
    { value: "ckb-IR", name: "Central Kurdish (Iran)" },
    { value: "cs", name: "Czech" },
    { value: "cs-CZ", name: "Czech (Czech Republic)" },
    { value: "cy", name: "Welsh" },
    { value: "cy-GB", name: "Welsh (United Kingdom)" },
    { value: "da", name: "Danish" },
    { value: "da-DK", name: "Danish (Denmark)" },
    { value: "da-GL", name: "Danish (Greenland)" },
    { value: "dav", name: "Taita" },
    { value: "dav-KE", name: "Taita (Kenya)" },
    { value: "de", name: "German" },
    { value: "de-AT", name: "German (Austria)" },
    { value: "de-BE", name: "German (Belgium)" },
    { value: "de-CH", name: "German (Switzerland)" },
    { value: "de-DE", name: "German (Germany)" },
    { value: "de-LI", name: "German (Liechtenstein)" },
    { value: "de-LU", name: "German (Luxembourg)" },
    { value: "dje", name: "Zarma" },
    { value: "dje-NE", name: "Zarma (Niger)" },
    { value: "dsb", name: "Lower Sorbian" },
    { value: "dsb-DE", name: "Lower Sorbian (Germany)" },
    { value: "dua", name: "Duala" },
    { value: "dua-CM", name: "Duala (Cameroon)" },
    { value: "dyo", name: "Jola-Fonyi" },
    { value: "dyo-SN", name: "Jola-Fonyi (Senegal)" },
    { value: "dz", name: "Dzongkha" },
    { value: "dz-BT", name: "Dzongkha (Bhutan)" },
    { value: "ebu", name: "Embu" },
    { value: "ebu-KE", name: "Embu (Kenya)" },
    { value: "ee", name: "Ewe" },
    { value: "ee-GH", name: "Ewe (Ghana)" },
    { value: "ee-TG", name: "Ewe (Togo)" },
    { value: "el", name: "Greek" },
    { value: "el-CY", name: "Greek (Cyprus)" },
    { value: "el-GR", name: "Greek (Greece)" },
    { value: "en", name: "English" },
    { value: "en-001", name: "English (World)" },
    { value: "en-150", name: "English (Europe)" },
    { value: "en-AD", name: "English (Andorra)" },
    { value: "en-AG", name: "English (Antigua & Barbuda)" },
    { value: "en-AI", name: "English (Anguilla)" },
    { value: "en-AL", name: "English (Albania)" },
    { value: "en-AS", name: "English (American Samoa)" },
    { value: "en-AT", name: "English (Austria)" },
    { value: "en-AU", name: "English (Australia)" },
    { value: "en-BA", name: "English (Bosnia & Herzegovina)" },
    { value: "en-BB", name: "English (Barbados)" },
    { value: "en-BE", name: "English (Belgium)" },
    { value: "en-BM", name: "English (Bermuda)" },
    { value: "en-BS", name: "English (Bahamas)" },
    { value: "en-BW", name: "English (Botswana)" },
    { value: "en-BZ", name: "English (Belize)" },
    { value: "en-CA", name: "English (Canada)" },
    { value: "en-CC", name: "English (Cocos [Keeling] Islands)" },
    { value: "en-CH", name: "English (Switzerland)" },
    { value: "en-CK", name: "English (Cook Islands)" },
    { value: "en-CM", name: "English (Cameroon)" },
    { value: "en-CX", name: "English (Christmas Island)" },
    { value: "en-CY", name: "English (Cyprus)" },
    { value: "en-CZ", name: "English (Czech Republic)" },
    { value: "en-DE", name: "English (Germany)" },
    { value: "en-DG", name: "English (Diego Garcia)" },
    { value: "en-DK", name: "English (Denmark)" },
    { value: "en-DM", name: "English (Dominica)" },
    { value: "en-EE", name: "English (Estonia)" },
    { value: "en-ER", name: "English (Eritrea)" },
    { value: "en-ES", name: "English (Spain)" },
    { value: "en-FI", name: "English (Finland)" },
    { value: "en-FJ", name: "English (Fiji)" },
    { value: "en-FK", name: "English (Falkland Islands)" },
    { value: "en-FM", name: "English (Micronesia)" },
    { value: "en-FR", name: "English (France)" },
    { value: "en-GB", name: "English (United Kingdom)" },
    { value: "en-GD", name: "English (Grenada)" },
    { value: "en-GG", name: "English (Guernsey)" },
    { value: "en-GH", name: "English (Ghana)" },
    { value: "en-GI", name: "English (Gibraltar)" },
    { value: "en-GM", name: "English (Gambia)" },
    { value: "en-GR", name: "English (Greece)" },
    { value: "en-GU", name: "English (Guam)" },
    { value: "en-GY", name: "English (Guyana)" },
    { value: "en-HK", name: "English (Hong Kong SAR China)" },
    { value: "en-HR", name: "English (Croatia)" },
    { value: "en-HU", name: "English (Hungary)" },
    { value: "en-IE", name: "English (Ireland)" },
    { value: "en-IL", name: "English (Israel)" },
    { value: "en-IM", name: "English (Isle of Man)" },
    { value: "en-IN", name: "English (India)" },
    { value: "en-IO", name: "English (British Indian Ocean Territory)" },
    { value: "en-IS", name: "English (Iceland)" },
    { value: "en-IT", name: "English (Italy)" },
    { value: "en-JE", name: "English (Jersey)" },
    { value: "en-JM", name: "English (Jamaica)" },
    { value: "en-KE", name: "English (Kenya)" },
    { value: "en-KI", name: "English (Kiribati)" },
    { value: "en-KN", name: "English (St. Kitts & Nevis)" },
    { value: "en-KY", name: "English (Cayman Islands)" },
    { value: "en-LC", name: "English (St. Lucia)" },
    { value: "en-LR", name: "English (Liberia)" },
    { value: "en-LS", name: "English (Lesotho)" },
    { value: "en-LT", name: "English (Lithuania)" },
    { value: "en-LU", name: "English (Luxembourg)" },
    { value: "en-LV", name: "English (Latvia)" },
    { value: "en-ME", name: "English (Montenegro)" },
    { value: "en-MG", name: "English (Madagascar)" },
    { value: "en-MH", name: "English (Marshall Islands)" },
    { value: "en-MO", name: "English (Macau SAR China)" },
    { value: "en-MP", name: "English (Northern Mariana Islands)" },
    { value: "en-MS", name: "English (Montserrat)" },
    { value: "en-MT", name: "English (Malta)" },
    { value: "en-MU", name: "English (Mauritius)" },
    { value: "en-MW", name: "English (Malawi)" },
    { value: "en-MY", name: "English (Malaysia)" },
    { value: "en-NA", name: "English (Namibia)" },
    { value: "en-NF", name: "English (Norfolk Island)" },
    { value: "en-NG", name: "English (Nigeria)" },
    { value: "en-NL", name: "English (Netherlands)" },
    { value: "en-NO", name: "English (Norway)" },
    { value: "en-NR", name: "English (Nauru)" },
    { value: "en-NU", name: "English (Niue)" },
    { value: "en-NZ", name: "English (New Zealand)" },
    { value: "en-PG", name: "English (Papua New Guinea)" },
    { value: "en-PH", name: "English (Philippines)" },
    { value: "en-PK", name: "English (Pakistan)" },
    { value: "en-PL", name: "English (Poland)" },
    { value: "en-PN", name: "English (Pitcairn Islands)" },
    { value: "en-PR", name: "English (Puerto Rico)" },
    { value: "en-PT", name: "English (Portugal)" },
    { value: "en-PW", name: "English (Palau)" },
    { value: "en-RO", name: "English (Romania)" },
    { value: "en-RU", name: "English (Russia)" },
    { value: "en-RW", name: "English (Rwanda)" },
    { value: "en-SB", name: "English (Solomon Islands)" },
    { value: "en-SC", name: "English (Seychelles)" },
    { value: "en-SD", name: "English (Sudan)" },
    { value: "en-SE", name: "English (Sweden)" },
    { value: "en-SG", name: "English (Singapore)" },
    { value: "en-SH", name: "English (St. Helena)" },
    { value: "en-SI", name: "English (Slovenia)" },
    { value: "en-SK", name: "English (Slovakia)" },
    { value: "en-SL", name: "English (Sierra Leone)" },
    { value: "en-SS", name: "English (South Sudan)" },
    { value: "en-SX", name: "English (Sint Maarten)" },
    { value: "en-SZ", name: "English (Swaziland)" },
    { value: "en-TC", name: "English (Turks & Caicos Islands)" },
    { value: "en-TK", name: "English (Tokelau)" },
    { value: "en-TO", name: "English (Tonga)" },
    { value: "en-TR", name: "English (Turkey)" },
    { value: "en-TT", name: "English (Trinidad & Tobago)" },
    { value: "en-TV", name: "English (Tuvalu)" },
    { value: "en-TZ", name: "English (Tanzania)" },
    { value: "en-UG", name: "English (Uganda)" },
    { value: "en-UM", name: "English (U.S. Outlying Islands)" },
    { value: "en-US", name: "English (United States)" },
    { value: "en-US-POSIX", name: "English (United States, Computer)" },
    { value: "en-VC", name: "English (St. Vincent & Grenadines)" },
    { value: "en-VG", name: "English (British Virgin Islands)" },
    { value: "en-VI", name: "English (U.S. Virgin Islands)" },
    { value: "en-VU", name: "English (Vanuatu)" },
    { value: "en-WS", name: "English (Samoa)" },
    { value: "en-ZA", name: "English (South Africa)" },
    { value: "en-ZM", name: "English (Zambia)" },
    { value: "en-ZW", name: "English (Zimbabwe)" },
    { value: "eo", name: "Esperanto" },
    { value: "es", name: "Spanish" },
    { value: "es-419", name: "Spanish (Latin America)" },
    { value: "es-AR", name: "Spanish (Argentina)" },
    { value: "es-BO", name: "Spanish (Bolivia)" },
    { value: "es-CL", name: "Spanish (Chile)" },
    { value: "es-CO", name: "Spanish (Colombia)" },
    { value: "es-CR", name: "Spanish (Costa Rica)" },
    { value: "es-CU", name: "Spanish (Cuba)" },
    { value: "es-DO", name: "Spanish (Dominican Republic)" },
    { value: "es-EA", name: "Spanish (Ceuta & Melilla)" },
    { value: "es-EC", name: "Spanish (Ecuador)" },
    { value: "es-ES", name: "Spanish (Spain)" },
    { value: "es-GQ", name: "Spanish (Equatorial Guinea)" },
    { value: "es-GT", name: "Spanish (Guatemala)" },
    { value: "es-HN", name: "Spanish (Honduras)" },
    { value: "es-IC", name: "Spanish (Canary Islands)" },
    { value: "es-MX", name: "Spanish (Mexico)" },
    { value: "es-NI", name: "Spanish (Nicaragua)" },
    { value: "es-PA", name: "Spanish (Panama)" },
    { value: "es-PE", name: "Spanish (Peru)" },
    { value: "es-PH", name: "Spanish (Philippines)" },
    { value: "es-PR", name: "Spanish (Puerto Rico)" },
    { value: "es-PY", name: "Spanish (Paraguay)" },
    { value: "es-SV", name: "Spanish (El Salvador)" },
    { value: "es-US", name: "Spanish (United States)" },
    { value: "es-UY", name: "Spanish (Uruguay)" },
    { value: "es-VE", name: "Spanish (Venezuela)" },
    { value: "et", name: "Estonian" },
    { value: "et-EE", name: "Estonian (Estonia)" },
    { value: "eu", name: "Basque" },
    { value: "eu-ES", name: "Basque (Spain)" },
    { value: "ewo", name: "Ewondo" },
    { value: "ewo-CM", name: "Ewondo (Cameroon)" },
    { value: "fa", name: "Persian" },
    { value: "fa-AF", name: "Persian (Afghanistan)" },
    { value: "fa-IR", name: "Persian (Iran)" },
    { value: "ff", name: "Fulah" },
    { value: "ff-CM", name: "Fulah (Cameroon)" },
    { value: "ff-GN", name: "Fulah (Guinea)" },
    { value: "ff-MR", name: "Fulah (Mauritania)" },
    { value: "ff-SN", name: "Fulah (Senegal)" },
    { value: "fi", name: "Finnish" },
    { value: "fi-FI", name: "Finnish (Finland)" },
    { value: "fil", name: "Filipino" },
    { value: "fil-PH", name: "Filipino (Philippines)" },
    { value: "fo", name: "Faroese" },
    { value: "fo-FO", name: "Faroese (Faroe Islands)" },
    { value: "fr", name: "French" },
    { value: "fr-BE", name: "French (Belgium)" },
    { value: "fr-BF", name: "French (Burkina Faso)" },
    { value: "fr-BI", name: "French (Burundi)" },
    { value: "fr-BJ", name: "French (Benin)" },
    { value: "fr-BL", name: "French (St. Barthélemy)" },
    { value: "fr-CA", name: "French (Canada)" },
    { value: "fr-CD", name: "French (Congo - Kinshasa)" },
    { value: "fr-CF", name: "French (Central African Republic)" },
    { value: "fr-CG", name: "French (Congo - Brazzaville)" },
    { value: "fr-CH", name: "French (Switzerland)" },
    { value: "fr-CI", name: "French (Côte d’Ivoire)" },
    { value: "fr-CM", name: "French (Cameroon)" },
    { value: "fr-DJ", name: "French (Djibouti)" },
    { value: "fr-DZ", name: "French (Algeria)" },
    { value: "fr-FR", name: "French (France)" },
    { value: "fr-GA", name: "French (Gabon)" },
    { value: "fr-GF", name: "French (French Guiana)" },
    { value: "fr-GN", name: "French (Guinea)" },
    { value: "fr-GP", name: "French (Guadeloupe)" },
    { value: "fr-GQ", name: "French (Equatorial Guinea)" },
    { value: "fr-HT", name: "French (Haiti)" },
    { value: "fr-KM", name: "French (Comoros)" },
    { value: "fr-LU", name: "French (Luxembourg)" },
    { value: "fr-MA", name: "French (Morocco)" },
    { value: "fr-MC", name: "French (Monaco)" },
    { value: "fr-MF", name: "French (St. Martin)" },
    { value: "fr-MG", name: "French (Madagascar)" },
    { value: "fr-ML", name: "French (Mali)" },
    { value: "fr-MQ", name: "French (Martinique)" },
    { value: "fr-MR", name: "French (Mauritania)" },
    { value: "fr-MU", name: "French (Mauritius)" },
    { value: "fr-NC", name: "French (New Caledonia)" },
    { value: "fr-NE", name: "French (Niger)" },
    { value: "fr-PF", name: "French (French Polynesia)" },
    { value: "fr-PM", name: "French (St. Pierre & Miquelon)" },
    { value: "fr-RE", name: "French (Réunion)" },
    { value: "fr-RW", name: "French (Rwanda)" },
    { value: "fr-SC", name: "French (Seychelles)" },
    { value: "fr-SN", name: "French (Senegal)" },
    { value: "fr-SY", name: "French (Syria)" },
    { value: "fr-TD", name: "French (Chad)" },
    { value: "fr-TG", name: "French (Togo)" },
    { value: "fr-TN", name: "French (Tunisia)" },
    { value: "fr-VU", name: "French (Vanuatu)" },
    { value: "fr-WF", name: "French (Wallis & Futuna)" },
    { value: "fr-YT", name: "French (Mayotte)" },
    { value: "fur", name: "Friulian" },
    { value: "fur-IT", name: "Friulian (Italy)" },
    { value: "fy", name: "Western Frisian" },
    { value: "fy-NL", name: "Western Frisian (Netherlands)" },
    { value: "ga", name: "Irish" },
    { value: "ga-IE", name: "Irish (Ireland)" },
    { value: "gd", name: "Scottish Gaelic" },
    { value: "gd-GB", name: "Scottish Gaelic (United Kingdom)" },
    { value: "gl", name: "Galician" },
    { value: "gl-ES", name: "Galician (Spain)" },
    { value: "gsw", name: "Swiss German" },
    { value: "gsw-CH", name: "Swiss German (Switzerland)" },
    { value: "gsw-FR", name: "Swiss German (France)" },
    { value: "gsw-LI", name: "Swiss German (Liechtenstein)" },
    { value: "gu", name: "Gujarati" },
    { value: "gu-IN", name: "Gujarati (India)" },
    { value: "guz", name: "Gusii" },
    { value: "guz-KE", name: "Gusii (Kenya)" },
    { value: "gv", name: "Manx" },
    { value: "gv-IM", name: "Manx (Isle of Man)" },
    { value: "ha", name: "Hausa" },
    { value: "ha-Latn", name: "Hausa (Latin)" },
    { value: "ha-Latn-GH", name: "Hausa (Latin, Ghana)" },
    { value: "ha-Latn-NE", name: "Hausa (Latin, Niger)" },
    { value: "ha-Latn-NG", name: "Hausa (Latin, Nigeria)" },
    { value: "haw", name: "Hawaiian" },
    { value: "haw-US", name: "Hawaiian (United States)" },
    { value: "he", name: "Hebrew" },
    { value: "he-IL", name: "Hebrew (Israel)" },
    { value: "hi", name: "Hindi" },
    { value: "hi-IN", name: "Hindi (India)" },
    { value: "hr", name: "Croatian" },
    { value: "hr-BA", name: "Croatian (Bosnia & Herzegovina)" },
    { value: "hr-HR", name: "Croatian (Croatia)" },
    { value: "hsb", name: "Upper Sorbian" },
    { value: "hsb-DE", name: "Upper Sorbian (Germany)" },
    { value: "hu", name: "Hungarian" },
    { value: "hu-HU", name: "Hungarian (Hungary)" },
    { value: "hy", name: "Armenian" },
    { value: "hy-AM", name: "Armenian (Armenia)" },
    { value: "id", name: "Indonesian" },
    { value: "id-ID", name: "Indonesian (Indonesia)" },
    { value: "ig", name: "Igbo" },
    { value: "ig-NG", name: "Igbo (Nigeria)" },
    { value: "ii", name: "Sichuan Yi" },
    { value: "ii-CN", name: "Sichuan Yi (China)" },
    { value: "is", name: "Icelandic" },
    { value: "is-IS", name: "Icelandic (Iceland)" },
    { value: "it", name: "Italian" },
    { value: "it-CH", name: "Italian (Switzerland)" },
    { value: "it-IT", name: "Italian (Italy)" },
    { value: "it-SM", name: "Italian (San Marino)" },
    { value: "iu", name: "Inuktitut" },
    {
      value: "iu-Cans",
      name: "Inuktitut (Unified Canadian Aboriginal Syllabics)",
    },
    {
      value: "iu-Cans-CA",
      name: "Inuktitut (Unified Canadian Aboriginal Syllabics, Canada)",
    },
    { value: "ja", name: "Japanese" },
    { value: "ja-JP", name: "Japanese (Japan)" },
    { value: "jgo", name: "Ngomba" },
    { value: "jgo-CM", name: "Ngomba (Cameroon)" },
    { value: "jmc", name: "Machame" },
    { value: "jmc-TZ", name: "Machame (Tanzania)" },
    { value: "ka", name: "Georgian" },
    { value: "ka-GE", name: "Georgian (Georgia)" },
    { value: "kab", name: "Kabyle" },
    { value: "kab-DZ", name: "Kabyle (Algeria)" },
    { value: "kam", name: "Kamba" },
    { value: "kam-KE", name: "Kamba (Kenya)" },
    { value: "kde", name: "Makonde" },
    { value: "kde-TZ", name: "Makonde (Tanzania)" },
    { value: "kea", name: "Kabuverdianu" },
    { value: "kea-CV", name: "Kabuverdianu (Cape Verde)" },
    { value: "khq", name: "Koyra Chiini" },
    { value: "khq-ML", name: "Koyra Chiini (Mali)" },
    { value: "ki", name: "Kikuyu" },
    { value: "ki-KE", name: "Kikuyu (Kenya)" },
    { value: "kk", name: "Kazakh" },
    { value: "kk-Cyrl", name: "Kazakh (Cyrillic)" },
    { value: "kk-Cyrl-KZ", name: "Kazakh (Cyrillic, Kazakhstan)" },
    { value: "kkj", name: "Kako" },
    { value: "kkj-CM", name: "Kako (Cameroon)" },
    { value: "kl", name: "Kalaallisut" },
    { value: "kl-GL", name: "Kalaallisut (Greenland)" },
    { value: "kln", name: "Kalenjin" },
    { value: "kln-KE", name: "Kalenjin (Kenya)" },
    { value: "km", name: "Khmer" },
    { value: "km-KH", name: "Khmer (Cambodia)" },
    { value: "kn", name: "Kannada" },
    { value: "kn-IN", name: "Kannada (India)" },
    { value: "ko", name: "Korean" },
    { value: "ko-KP", name: "Korean (North Korea)" },
    { value: "ko-KR", name: "Korean (South Korea)" },
    { value: "kok", name: "Konkani" },
    { value: "kok-IN", name: "Konkani (India)" },
    { value: "ks", name: "Kashmiri" },
    { value: "ks-Arab", name: "Kashmiri (Arabic)" },
    { value: "ks-Arab-IN", name: "Kashmiri (Arabic, India)" },
    { value: "ksb", name: "Shambala" },
    { value: "ksb-TZ", name: "Shambala (Tanzania)" },
    { value: "ksf", name: "Bafia" },
    { value: "ksf-CM", name: "Bafia (Cameroon)" },
    { value: "ksh", name: "Colognian" },
    { value: "ksh-DE", name: "Colognian (Germany)" },
    { value: "kw", name: "Cornish" },
    { value: "kw-GB", name: "Cornish (United Kingdom)" },
    { value: "ky", name: "Kyrgyz" },
    { value: "ky-Cyrl", name: "Kyrgyz (Cyrillic)" },
    { value: "ky-Cyrl-KG", name: "Kyrgyz (Cyrillic, Kyrgyzstan)" },
    { value: "lag", name: "Langi" },
    { value: "lag-TZ", name: "Langi (Tanzania)" },
    { value: "lb", name: "Luxembourgish" },
    { value: "lb-LU", name: "Luxembourgish (Luxembourg)" },
    { value: "lg", name: "Ganda" },
    { value: "lg-UG", name: "Ganda (Uganda)" },
    { value: "lkt", name: "Lakota" },
    { value: "lkt-US", name: "Lakota (United States)" },
    { value: "ln", name: "Lingala" },
    { value: "ln-AO", name: "Lingala (Angola)" },
    { value: "ln-CD", name: "Lingala (Congo - Kinshasa)" },
    { value: "ln-CF", name: "Lingala (Central African Republic)" },
    { value: "ln-CG", name: "Lingala (Congo - Brazzaville)" },
    { value: "lo", name: "Lao" },
    { value: "lo-LA", name: "Lao (Laos)" },
    { value: "valueIdentifier", name: "Description" },
    { value: "lt", name: "Lithuanian" },
    { value: "lt-LT", name: "Lithuanian (Lithuania)" },
    { value: "lu", name: "Luba-Katanga" },
    { value: "lu-CD", name: "Luba-Katanga (Congo - Kinshasa)" },
    { value: "luo", name: "Luo" },
    { value: "luo-KE", name: "Luo (Kenya)" },
    { value: "luy", name: "Luyia" },
    { value: "luy-KE", name: "Luyia (Kenya)" },
    { value: "lv", name: "Latvian" },
    { value: "lv-LV", name: "Latvian (Latvia)" },
    { value: "mas", name: "Masai" },
    { value: "mas-KE", name: "Masai (Kenya)" },
    { value: "mas-TZ", name: "Masai (Tanzania)" },
    { value: "mer", name: "Meru" },
    { value: "mer-KE", name: "Meru (Kenya)" },
    { value: "mfe", name: "Morisyen" },
    { value: "mfe-MU", name: "Morisyen (Mauritius)" },
    { value: "mg", name: "Malagasy" },
    { value: "mg-MG", name: "Malagasy (Madagascar)" },
    { value: "mgh", name: "Makhuwa-Meetto" },
    { value: "mgh-MZ", name: "Makhuwa-Meetto (Mozambique)" },
    { value: "mgo", name: "Metaʼ" },
    { value: "mgo-CM", name: "Metaʼ (Cameroon)" },
    { value: "mk", name: "Macedonian" },
    { value: "mk-MK", name: "Macedonian (Macedonia)" },
    { value: "ml", name: "Malayalam" },
    { value: "ml-IN", name: "Malayalam (India)" },
    { value: "mn", name: "Mongolian" },
    { value: "mn-Cyrl", name: "Mongolian (Cyrillic)" },
    { value: "mn-Cyrl-MN", name: "Mongolian (Cyrillic, Mongolia)" },
    { value: "mr", name: "Marathi" },
    { value: "mr-IN", name: "Marathi (India)" },
    { value: "ms", name: "Malay" },
    { value: "ms-Arab", name: "Malay (Arabic)" },
    { value: "ms-Arab-BN", name: "Malay (Arabic, Brunei)" },
    { value: "ms-Arab-MY", name: "Malay (Arabic, Malaysia)" },
    { value: "ms-Latn", name: "Malay (Latin)" },
    { value: "ms-Latn-BN", name: "Malay (Latin, Brunei)" },
    { value: "ms-Latn-MY", name: "Malay (Latin, Malaysia)" },
    { value: "ms-Latn-SG", name: "Malay (Latin, Singapore)" },
    { value: "mt", name: "Maltese" },
    { value: "mt-MT", name: "Maltese (Malta)" },
    { value: "mua", name: "Mundang" },
    { value: "mua-CM", name: "Mundang (Cameroon)" },
    { value: "my", name: "Burmese" },
    { value: "my-MM", name: "Burmese (Myanmar [Burma])" },
    { value: "naq", name: "Nama" },
    { value: "naq-NA", name: "Nama (Namibia)" },
    { value: "nb", name: "Norwegian Bokmål" },
    { value: "nb-NO", name: "Norwegian Bokmål (Norway)" },
    { value: "nb-SJ", name: "Norwegian Bokmål (Svalbard & Jan Mayen)" },
    { value: "nd", name: "North Ndebele" },
    { value: "nd-ZW", name: "North Ndebele (Zimbabwe)" },
    { value: "ne", name: "Nepali" },
    { value: "ne-IN", name: "Nepali (India)" },
    { value: "ne-NP", name: "Nepali (Nepal)" },
    { value: "nl", name: "Dutch" },
    { value: "nl-AW", name: "Dutch (Aruba)" },
    { value: "nl-BE", name: "Dutch (Belgium)" },
    { value: "nl-BQ", name: "Dutch (Caribbean Netherlands)" },
    { value: "nl-CW", name: "Dutch (Curaçao)" },
    { value: "nl-NL", name: "Dutch (Netherlands)" },
    { value: "nl-SR", name: "Dutch (Suriname)" },
    { value: "nl-SX", name: "Dutch (Sint Maarten)" },
    { value: "nmg", name: "Kwasio" },
    { value: "nmg-CM", name: "Kwasio (Cameroon)" },
    { value: "nn", name: "Norwegian Nynorsk" },
    { value: "nn-NO", name: "Norwegian Nynorsk (Norway)" },
    { value: "nnh", name: "Ngiemboon" },
    { value: "nnh-CM", name: "Ngiemboon (Cameroon)" },
    { value: "nus", name: "Nuer" },
    { value: "nus-SD", name: "Nuer (Sudan)" },
    { value: "nyn", name: "Nyankole" },
    { value: "nyn-UG", name: "Nyankole (Uganda)" },
    { value: "om", name: "Oromo" },
    { value: "om-ET", name: "Oromo (Ethiopia)" },
    { value: "om-KE", name: "Oromo (Kenya)" },
    { value: "or", name: "Oriya" },
    { value: "or-IN", name: "Oriya (India)" },
    { value: "os", name: "Ossetic" },
    { value: "os-GE", name: "Ossetic (Georgia)" },
    { value: "os-RU", name: "Ossetic (Russia)" },
    { value: "pa", name: "Punjabi" },
    { value: "pa-Arab", name: "Punjabi (Arabic)" },
    { value: "pa-Arab-PK", name: "Punjabi (Arabic, Pakistan)" },
    { value: "pa-Guru", name: "Punjabi (Gurmukhi)" },
    { value: "pa-Guru-IN", name: "Punjabi (Gurmukhi, India)" },
    { value: "pl", name: "Polish" },
    { value: "pl-PL", name: "Polish (Poland)" },
    { value: "ps", name: "Pashto" },
    { value: "ps-AF", name: "Pashto (Afghanistan)" },
    { value: "pt", name: "Portuguese" },
    { value: "pt-AO", name: "Portuguese (Angola)" },
    { value: "pt-BR", name: "Portuguese (Brazil)" },
    { value: "pt-CV", name: "Portuguese (Cape Verde)" },
    { value: "pt-GW", name: "Portuguese (Guinea-Bissau)" },
    { value: "pt-MO", name: "Portuguese (Macau SAR China)" },
    { value: "pt-MZ", name: "Portuguese (Mozambique)" },
    { value: "pt-PT", name: "Portuguese (Portugal)" },
    { value: "pt-ST", name: "Portuguese (São Tomé & Príncipe)" },
    { value: "pt-TL", name: "Portuguese (Timor-Leste)" },
    { value: "qu", name: "Quechua" },
    { value: "qu-BO", name: "Quechua (Bolivia)" },
    { value: "qu-EC", name: "Quechua (Ecuador)" },
    { value: "qu-PE", name: "Quechua (Peru)" },
    { value: "rm", name: "Romansh" },
    { value: "rm-CH", name: "Romansh (Switzerland)" },
    { value: "rn", name: "Rundi" },
    { value: "rn-BI", name: "Rundi (Burundi)" },
    { value: "ro", name: "Romanian" },
    { value: "ro-MD", name: "Romanian (Moldova)" },
    { value: "ro-RO", name: "Romanian (Romania)" },
    { value: "rof", name: "Rombo" },
    { value: "rof-TZ", name: "Rombo (Tanzania)" },
    { value: "ru", name: "Russian" },
    { value: "ru-BY", name: "Russian (Belarus)" },
    { value: "ru-KG", name: "Russian (Kyrgyzstan)" },
    { value: "ru-KZ", name: "Russian (Kazakhstan)" },
    { value: "ru-MD", name: "Russian (Moldova)" },
    { value: "ru-RU", name: "Russian (Russia)" },
    { value: "ru-UA", name: "Russian (Ukraine)" },
    { value: "rw", name: "Kinyarwanda" },
    { value: "rw-RW", name: "Kinyarwanda (Rwanda)" },
    { value: "rwk", name: "Rwa" },
    { value: "rwk-TZ", name: "Rwa (Tanzania)" },
    { value: "sah", name: "Sakha" },
    { value: "sah-RU", name: "Sakha (Russia)" },
    { value: "saq", name: "Samburu" },
    { value: "saq-KE", name: "Samburu (Kenya)" },
    { value: "sbp", name: "Sangu" },
    { value: "sbp-TZ", name: "Sangu (Tanzania)" },
    { value: "se", name: "Northern Sami" },
    { value: "se-FI", name: "Northern Sami (Finland)" },
    { value: "se-NO", name: "Northern Sami (Norway)" },
    { value: "se-SE", name: "Northern Sami (Sweden)" },
    { value: "seh", name: "Sena" },
    { value: "seh-MZ", name: "Sena (Mozambique)" },
    { value: "ses", name: "Koyraboro Senni" },
    { value: "ses-ML", name: "Koyraboro Senni (Mali)" },
    { value: "sg", name: "Sango" },
    { value: "sg-CF", name: "Sango (Central African Republic)" },
    { value: "shi", name: "Tachelhit" },
    { value: "shi-Latn", name: "Tachelhit (Latin)" },
    { value: "shi-Latn-MA", name: "Tachelhit (Latin, Morocco)" },
    { value: "shi-Tfng", name: "Tachelhit (Tifinagh)" },
    { value: "shi-Tfng-MA", name: "Tachelhit (Tifinagh, Morocco)" },
    { value: "si", name: "Sinhala" },
    { value: "si-LK", name: "Sinhala (Sri Lanka)" },
    { value: "sk", name: "Slovak" },
    { value: "sk-SK", name: "Slovak (Slovakia)" },
    { value: "sl", name: "Slovenian" },
    { value: "sl-SI", name: "Slovenian (Slovenia)" },
    { value: "smn", name: "Inari Sami" },
    { value: "smn-FI", name: "Inari Sami (Finland)" },
    { value: "sn", name: "Shona" },
    { value: "sn-ZW", name: "Shona (Zimbabwe)" },
    { value: "so", name: "Somali" },
    { value: "so-DJ", name: "Somali (Djibouti)" },
    { value: "so-ET", name: "Somali (Ethiopia)" },
    { value: "so-KE", name: "Somali (Kenya)" },
    { value: "so-SO", name: "Somali (Somalia)" },
    { value: "sq", name: "Albanian" },
    { value: "sq-AL", name: "Albanian (Albania)" },
    { value: "sq-MK", name: "Albanian (Macedonia)" },
    { value: "sq-XK", name: "Albanian (Kosovo)" },
    { value: "sr", name: "Serbian" },
    { value: "sr-Cyrl", name: "Serbian (Cyrillic)" },
    { value: "sr-Cyrl-BA", name: "Serbian (Cyrillic, Bosnia & Herzegovina)" },
    { value: "sr-Cyrl-ME", name: "Serbian (Cyrillic, Montenegro)" },
    { value: "sr-Cyrl-RS", name: "Serbian (Cyrillic, Serbia)" },
    { value: "sr-Cyrl-XK", name: "Serbian (Cyrillic, Kosovo)" },
    { value: "sr-Latn", name: "Serbian (Latin)" },
    { value: "sr-Latn-BA", name: "Serbian (Latin, Bosnia & Herzegovina)" },
    { value: "sr-Latn-ME", name: "Serbian (Latin, Montenegro)" },
    { value: "sr-Latn-RS", name: "Serbian (Latin, Serbia)" },
    { value: "sr-Latn-XK", name: "Serbian (Latin, Kosovo)" },
    { value: "sv", name: "Swedish" },
    { value: "sv-AX", name: "Swedish (Åland Islands)" },
    { value: "sv-FI", name: "Swedish (Finland)" },
    { value: "sv-SE", name: "Swedish (Sweden)" },
    { value: "sw", name: "Swahili" },
    { value: "sw-CD", name: "Swahili (Congo - Kinshasa)" },
    { value: "sw-KE", name: "Swahili (Kenya)" },
    { value: "sw-TZ", name: "Swahili (Tanzania)" },
    { value: "sw-UG", name: "Swahili (Uganda)" },
    { value: "ta", name: "Tamil" },
    { value: "ta-IN", name: "Tamil (India)" },
    { value: "ta-LK", name: "Tamil (Sri Lanka)" },
    { value: "ta-MY", name: "Tamil (Malaysia)" },
    { value: "ta-SG", name: "Tamil (Singapore)" },
    { value: "te", name: "Telugu" },
    { value: "te-IN", name: "Telugu (India)" },
    { value: "teo", name: "Teso" },
    { value: "teo-KE", name: "Teso (Kenya)" },
    { value: "teo-UG", name: "Teso (Uganda)" },
    { value: "tg", name: "Tajik" },
    { value: "tg-Cyrl", name: "Tajik (Cyrillic)" },
    { value: "tg-Cyrl-TJ", name: "Tajik (Cyrillic, Tajikistan)" },
    { value: "th", name: "Thai" },
    { value: "th-TH", name: "Thai (Thailand)" },
    { value: "ti", name: "Tigrinya" },
    { value: "ti-ER", name: "Tigrinya (Eritrea)" },
    { value: "ti-ET", name: "Tigrinya (Ethiopia)" },
    { value: "tk", name: "Turkmen" },
    { value: "tk-Latn", name: "Turkmen (Latin)" },
    { value: "tk-Latn-TM", name: "Turkmen (Latin, Turkmenistan)" },
    { value: "to", name: "Tongan" },
    { value: "to-TO", name: "Tongan (Tonga)" },
    { value: "tr", name: "Turkish" },
    { value: "tr-CY", name: "Turkish (Cyprus)" },
    { value: "tr-TR", name: "Turkish (Turkey)" },
    { value: "twq", name: "Tasawaq" },
    { value: "twq-NE", name: "Tasawaq (Niger)" },
    { value: "tzm", name: "Central Atlas Tamazight" },
    { value: "tzm-Latn", name: "Central Atlas Tamazight (Latin)" },
    { value: "tzm-Latn-MA", name: "Central Atlas Tamazight (Latin, Morocco)" },
    { value: "ug", name: "Uyghur" },
    { value: "ug-Arab", name: "Uyghur (Arabic)" },
    { value: "ug-Arab-CN", name: "Uyghur (Arabic, China)" },
    { value: "uk", name: "Ukrainian" },
    { value: "uk-UA", name: "Ukrainian (Ukraine)" },
    { value: "ur", name: "Urdu" },
    { value: "ur-IN", name: "Urdu (India)" },
    { value: "ur-PK", name: "Urdu (Pakistan)" },
    { value: "uz", name: "Uzbek" },
    { value: "uz-Arab", name: "Uzbek (Arabic)" },
    { value: "uz-Arab-AF", name: "Uzbek (Arabic, Afghanistan)" },
    { value: "uz-Cyrl", name: "Uzbek (Cyrillic)" },
    { value: "uz-Cyrl-UZ", name: "Uzbek (Cyrillic, Uzbekistan)" },
    { value: "uz-Latn", name: "Uzbek (Latin)" },
    { value: "uz-Latn-UZ", name: "Uzbek (Latin, Uzbekistan)" },
    { value: "vai", name: "Vai" },
    { value: "vai-Latn", name: "Vai (Latin)" },
    { value: "vai-Latn-LR", name: "Vai (Latin, Liberia)" },
    { value: "vai-Vaii", name: "Vai (Vai)" },
    { value: "vai-Vaii-LR", name: "Vai (Vai, Liberia)" },
    { value: "vi", name: "Vietnamese" },
    { value: "vi-VN", name: "Vietnamese (Vietnam)" },
    { value: "vun", name: "Vunjo" },
    { value: "vun-TZ", name: "Vunjo (Tanzania)" },
    { value: "wae", name: "Walser" },
    { value: "wae-CH", name: "Walser (Switzerland)" },
    { value: "xog", name: "Soga" },
    { value: "xog-UG", name: "Soga (Uganda)" },
    { value: "yav", name: "Yangben" },
    { value: "yav-CM", name: "Yangben (Cameroon)" },
    { value: "yi", name: "Yiddish" },
    { value: "yi-001", name: "Yiddish (World)" },
    { value: "yo", name: "Yoruba" },
    { value: "yo-BJ", name: "Yoruba (Benin)" },
    { value: "yo-NG", name: "Yoruba (Nigeria)" },
    { value: "zgh", name: "Standard Moroccan Tamazight" },
    { value: "zgh-MA", name: "Standard Moroccan Tamazight (Morocco)" },
    { value: "zh", name: "Chinese" },
    { value: "zh-Hans", name: "Chinese (Simplified)" },
    { value: "zh-Hans-CN", name: "Chinese (Simplified, China)" },
    { value: "zh-Hans-HK", name: "Chinese (Simplified, Hong Kong SAR China)" },
    { value: "zh-Hans-MO", name: "Chinese (Simplified, Macau SAR China)" },
    { value: "zh-Hans-SG", name: "Chinese (Simplified, Singapore)" },
    { value: "zh-Hant", name: "Chinese (Traditional)" },
    { value: "zh-Hant-HK", name: "Chinese (Traditional, Hong Kong SAR China)" },
    { value: "zh-Hant-MO", name: "Chinese (Traditional, Macau SAR China)" },
    { value: "zh-Hant-TW", name: "Chinese (Traditional, Taiwan)" },
    { value: "zu", name: "Zulu" },
    { value: "zu-ZA", name: "Zulu (South Africa)" },
  ],

  languages: [
    { id: "en", name: "English" },
    { id: "fr", name: "Français" },
  ],

  theme: {
    welcome: "Welcome",
    login: "Login",
    borrow: "Borrow",
    return: "Return",
    renew: "Renew",
    reservation: "Reservation",
    thirdParty: "ThirdParty",
    pay: "Pay",
    top: "Top",
    bottom: "Bottom",
    buttons: "Buttons",
    headings: "Headings",
    images: "Images",
    text: "Text",
    fontSize: "Font size",
    foreground: "Foreground",
    weight: "Weight",
    horizontalAlign: "Horizontal align",
    verticalAlign: "Vertical align",
    visible: "Visible",
    rightCol: "Right column",
    backBtn: "Back button",
    helpBtn: "Help button",
    issueBtn: "Issue button",
    returnBtn: "Return button",
    renewBtn: "Renew button",
    payBtn: "Pay button",
    reservationBtn: "Reservation button",
    thirdPartyBtn: "ThirdParty button",
    keypadBtn: "KeyPad button",
    printReceiptBtn: "PrintReceipt button",
    cardBtn: "Cardpayment button",
    headingImg: "Heading image",
    rightImg: "Right image",
    leftImg: "Left image",
    table: "Table",
    statusVisible: "Status visible",
    payVisible: "Pay visible",
  },

  settings: {
    back: "Back",
    lms: "LMS/ILS",
    print: "Print",
    payment3: "Payment III",
    chippin: "Payment Settings",
    EMDevice: "EM Device",
    money: "Money",
    timing: "Timing",
    sorting: "Sorting",
    userCard: "User card",
    constants: "Constants",
    receipts: "Receipts",
    emailReceipts: "Email receipts",
    connectionType: "Connection type",
    hostname: "Hostname",
    socket: "Socket",
    port: "Port",
    lmsUser: "LMS Username",
    lmsPwd: "LMS password",
    institutionId: "Institution ID",
    terminalPwd: "Terminal password",
    locationCode: "Location code",
    borrowDue: "Offline loan term (days)",
    sendLoginToLMS: "Send login to LMS",
    printOp1: "Force receipt on finish screen or screen change return",
    printOp2: "Force receipt on finish screen or screen change pay",
    printOp3: "Force receipt on finish screen or screen change renew",
    printOp4: "Force receipt on finish screen or screen change reservation",
    printOp5: "Force receipt on finish screen or screen change borrow",
    printOp6: "End session after printing",
    printOp7: "Print Button Visibility",
    printIdType: "Print ID type",
    patronName: "Patron name",
    patronId: "Patron ID (last 4 digits e.g. xxxxxxxx4567)",
    design: "Design",
    bgColor: "Background color",
    bgImg: "Background image",
    fontFamily: "Font family",
    warningPopUp: "Warning Pop-up",
    failurePopUp: "General failure Pop-up",
    enable3payment: "Enable III payments",
    user: "Username",
    pwd: "Password",
    SSLEnabled: "SSL enabled",
    validateSSL: "Validate SSL certificate",
    yespay: "Yes Pay",
    easyvPort: "EasyV terminal port",
    easyvReceiptPort: "EasyV terminal receipt port",
    minCardPayment: "Minimum card payment",
    creditCall: "Credit Call",
    terminalId: "Terminal ID",
    serverAddress: "Server address",
    capita: "Capita",
    smartClientIP: "Smart client IP",
    smartClientPort: "Smart client port",
    machineCode: "Machine code",
    siteID: "Site ID",
    userCode: "User code",
    fundCode: "Fund code",
    portName: "Port name",
    baudRate: "Baud rate",
    parity: "Parity",
    dataBits: "Data bits",
    stopBits: "Stop bits",
    cacheBalance: "Cache balance during check out",
    debitPositive: "Debit returned as positive value",
    maxDebit: "Max account debit",
    maxCredit: "Max account credit",
    currencySymbol: "Currency symbol",
    currencyType: "Currency type",
    cardScanningTimeout: "Card scanning timeout (seconds)",
    LMSInterval: "LMS connection timeout (seconds)",
    screenTimeout: "Screen idle timeout (seconds)",
    warningTimeout: "Warning pop-up timeout (seconds)",
    CH: "CH fields in bin (comma separated)",
    CK: "CK fields in bin (comma separated)",
    CL: "CL fields in bin (comma separated)",
    CR: "CR fields in bin (comma separated)",
    showSorting: "Show sorting",
    leftBin: "Item with an alert",
    rightBin: "Item without an alert",
    swapSides: "Swap side for alerts",
    wording: "Wording",
    maskingField1: "Masking field 1",
    maskingField2: "Masking field 2",
    maskingField3: "Masking field 3",
    noChars: "No characters",
    header: "Header",
    footer: "Footer",
    borrowMaxDebit: "Enable borrow on max debit",
    returnMaxDebit: "Enable return on max debit",
    renewalsMaxDebit: "Enable renewals on max debit",
    payMaxDebit: "Enable pay on max debit",
    reservationMaxDebit: "Enable reservation on max debit",
    emailReceiptOp1: "Force email on finish screen or screen change return",
    emailReceiptOp2: "Force email on finish screen or screen change pay",
    emailReceiptOp3: "Force email on finish screen or screen change renew",
    emailReceiptOp4:
      "Force email on finish screen or screen change reservation",
    emailReceiptOp5: "Force email on finish screen or screen change borrow",
    emailReceiptOp6: "End session after email",
    emailReceiptOp7: "Email Button Visibility",
  },
};
