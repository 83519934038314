/* @flow */

export const REQUEST = "REQUEST";
export const FATAL_ERROR = "FATAL_ERROR";
export const DO_LOGOUT = "DO_LOGOUT";
export const DO_LOGIN = "DO_LOGIN";
export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";

export const INIT = "INIT";
export const UI_SET = "UI_SET";
export const UI_UNSET = "UI_UNSET";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_LANGAUGES = "SET_LANGAUGES";
export const SET_THEME = "SET_THEME";

export const SUCCESS_MSG = "_SUCCESS_MSG";
export const START_SUFFIX = "_START";
export const SUCCESS_SUFFIX = "_SUCCESS";
export const ERROR_SUFFIX = "_ERROR";

export const LOGIN_PREFIX = "LOGIN";
export const LOGIN_START = LOGIN_PREFIX + START_SUFFIX;
export const LOGIN_SUCCESS = LOGIN_PREFIX + SUCCESS_SUFFIX;
export const LOGIN_FAIL = LOGIN_PREFIX + ERROR_SUFFIX;

export const GET_LIBS_PREFIX = "GET_LIBS";
export const GET_LIBS_START = GET_LIBS_PREFIX + START_SUFFIX;
export const GET_LIBS_SUCCESS = GET_LIBS_PREFIX + SUCCESS_SUFFIX;
export const GET_LIBS_FAIL = GET_LIBS_PREFIX + ERROR_SUFFIX;

export const CREATE_LIB_PREFIX = "CREATE_LIB";
export const CREATE_LIB_START = CREATE_LIB_PREFIX + START_SUFFIX;
export const CREATE_LIB_SUCCESS = CREATE_LIB_PREFIX + SUCCESS_SUFFIX;
export const CREATE_LIB_FAIL = CREATE_LIB_PREFIX + ERROR_SUFFIX;

export const EDIT_LIB_PREFIX = "EDIT_LIB";
export const EDIT_LIB_START = EDIT_LIB_PREFIX + START_SUFFIX;
export const EDIT_LIB_SUCCESS = EDIT_LIB_PREFIX + SUCCESS_SUFFIX;
export const EDIT_LIB_FAIL = EDIT_LIB_PREFIX + ERROR_SUFFIX;

export const CREATE_SC_PREFIX = "CREATE_SC";
export const CREATE_SC_START = CREATE_SC_PREFIX + START_SUFFIX;
export const CREATE_SC_SUCCESS = CREATE_SC_PREFIX + SUCCESS_SUFFIX;
export const CREATE_SC_FAIL = CREATE_SC_PREFIX + ERROR_SUFFIX;

export const EDIT_SC_PREFIX = "EDIT_SC";
export const EDIT_SC_START = EDIT_SC_PREFIX + START_SUFFIX;
export const EDIT_SC_SUCCESS = EDIT_SC_PREFIX + SUCCESS_SUFFIX;
export const EDIT_SC_FAIL = EDIT_SC_PREFIX + ERROR_SUFFIX;

export const GET_SETTINGS_PREFIX = "GET_SETTINGS";
export const GET_SETTINGS_START = GET_SETTINGS_PREFIX + START_SUFFIX;
export const GET_SETTINGS_SUCCESS = GET_SETTINGS_PREFIX + SUCCESS_SUFFIX;
export const GET_SETTINGS_FAIL = GET_SETTINGS_PREFIX + ERROR_SUFFIX;

export const SAVE_SETTINGS_PREFIX = "SAVE_SETTINGS";
export const SAVE_SETTINGS_START = SAVE_SETTINGS_PREFIX + START_SUFFIX;
export const SAVE_SETTINGS_SUCCESS = SAVE_SETTINGS_PREFIX + SUCCESS_SUFFIX;
export const SAVE_SETTINGS_FAIL = SAVE_SETTINGS_PREFIX + ERROR_SUFFIX;

export const GET_LANGUAGES_PREFIX = "GET_LANGUAGES";
export const GET_LANGUAGES_START = GET_LANGUAGES_PREFIX + START_SUFFIX;
export const GET_LANGUAGES_SUCCESS = GET_LANGUAGES_PREFIX + SUCCESS_SUFFIX;
export const GET_LANGUAGES_FAIL = GET_LANGUAGES_PREFIX + ERROR_SUFFIX;

export const SAVE_LANGUAGES_PREFIX = "SAVE_LANGUAGES";
export const SAVE_LANGUAGES_START = SAVE_LANGUAGES_PREFIX + START_SUFFIX;
export const SAVE_LANGUAGES_SUCCESS = SAVE_LANGUAGES_PREFIX + SUCCESS_SUFFIX;
export const SAVE_LANGUAGES_FAIL = SAVE_LANGUAGES_PREFIX + ERROR_SUFFIX;

export const DELETE_LIB_PREFIX = "DELETE_LIB";
export const DELETE_LIB_START = DELETE_LIB_PREFIX + START_SUFFIX;
export const DELETE_LIB_SUCCESS = DELETE_LIB_PREFIX + SUCCESS_SUFFIX;
export const DELETE_LIB_FAIL = DELETE_LIB_PREFIX + ERROR_SUFFIX;

export const DELETE_SC_PREFIX = "DELETE_SC";
export const DELETE_SC_START = DELETE_SC_PREFIX + START_SUFFIX;
export const DELETE_SC_SUCCESS = DELETE_SC_PREFIX + SUCCESS_SUFFIX;
export const DELETE_SC_FAIL = DELETE_SC_PREFIX + ERROR_SUFFIX;

export const GET_THEMES_PREFIX = "GET_THEMES";
export const GET_THEMES_START = GET_THEMES_PREFIX + START_SUFFIX;
export const GET_THEMES_SUCCESS = GET_THEMES_PREFIX + SUCCESS_SUFFIX;
export const GET_THEMES_FAIL = GET_THEMES_PREFIX + ERROR_SUFFIX;

export const SAVE_THEMES_PREFIX = "SAVE_THEMES";
export const SAVE_THEMES_START = SAVE_THEMES_PREFIX + START_SUFFIX;
export const SAVE_THEMES_SUCCESS = SAVE_THEMES_PREFIX + SUCCESS_SUFFIX;
export const SAVE_THEMES_FAIL = SAVE_THEMES_PREFIX + ERROR_SUFFIX;

export const GET_INFO_PREFIX = "GET_INFO";
export const GET_INFO_START = GET_INFO_PREFIX + START_SUFFIX;
export const GET_INFO_SUCCESS = GET_INFO_PREFIX + SUCCESS_SUFFIX;
export const GET_INFO_FAIL = GET_INFO_PREFIX + ERROR_SUFFIX;

export const DO_UPDATE_PREFIX = "DO_UPDATE";
export const DO_UPDATE_START = DO_UPDATE_PREFIX + START_SUFFIX;
export const DO_UPDATE_SUCCESS = DO_UPDATE_PREFIX + SUCCESS_SUFFIX;
export const DO_UPDATE_FAIL = DO_UPDATE_PREFIX + ERROR_SUFFIX;

export const GET_USERS_PREFIX = "GET_USERS";
export const GET_USERS_START = GET_USERS_PREFIX + START_SUFFIX;
export const GET_USERS_SUCCESS = GET_USERS_PREFIX + SUCCESS_SUFFIX;
export const GET_USERS_FAIL = GET_USERS_PREFIX + ERROR_SUFFIX;

export const CREATE_USERS_PREFIX = "CREATE_USERS";
export const CREATE_USERS_START = CREATE_USERS_PREFIX + START_SUFFIX;
export const CREATE_USERS_SUCCESS = CREATE_USERS_PREFIX + SUCCESS_SUFFIX;
export const CREATE_USERS_FAIL = CREATE_USERS_PREFIX + ERROR_SUFFIX;

export const UPDATE_USERS_PREFIX = "UPDATE_USERS";
export const UPDATE_USERS_START = UPDATE_USERS_PREFIX + START_SUFFIX;
export const UPDATE_USERS_SUCCESS = UPDATE_USERS_PREFIX + SUCCESS_SUFFIX;
export const UPDATE_USERS_FAIL = UPDATE_USERS_PREFIX + ERROR_SUFFIX;

export const DELETE_USERS_PREFIX = "DELETE_USERS";
export const DELETE_USERS_START = DELETE_USERS_PREFIX + START_SUFFIX;
export const DELETE_USERS_SUCCESS = DELETE_USERS_PREFIX + SUCCESS_SUFFIX;
export const DELETE_USERS_FAIL = DELETE_USERS_PREFIX + ERROR_SUFFIX;

export const GET_COUNTS_PREFIX = "GET_COUNTS";
export const GET_COUNTS_START = GET_COUNTS_PREFIX + START_SUFFIX;
export const GET_COUNTS_SUCCESS = GET_COUNTS_PREFIX + SUCCESS_SUFFIX;
export const GET_COUNTS_FAIL = GET_COUNTS_PREFIX + ERROR_SUFFIX;

export const EXPORT_COUNTS_PREFIX = "EXPORT_COUNTS";
export const EXPORT_COUNTS_START = EXPORT_COUNTS_PREFIX + START_SUFFIX;
export const EXPORT_COUNTS_SUCCESS = EXPORT_COUNTS_PREFIX + SUCCESS_SUFFIX;
export const EXPORT_COUNTS_FAIL = EXPORT_COUNTS_PREFIX + ERROR_SUFFIX;

export const GET_MAIN_STATS_PREFIX = "GET_MAIN_STATS";
export const GET_MAIN_STATS_START = GET_MAIN_STATS_PREFIX + START_SUFFIX;
export const GET_MAIN_STATS_SUCCESS = GET_MAIN_STATS_PREFIX + SUCCESS_SUFFIX;
export const GET_MAIN_STATS_FAIL = GET_MAIN_STATS_PREFIX + ERROR_SUFFIX;

export const GET_TOOLTIPS_PREFIX = "GET_TOOLTIPS";
export const GET_TOOLTIPS_START = GET_TOOLTIPS_PREFIX + START_SUFFIX;
export const GET_TOOLTIPS_SUCCESS = GET_TOOLTIPS_PREFIX + SUCCESS_SUFFIX;
export const GET_TOOLTIPS_FAIL = GET_TOOLTIPS_PREFIX + ERROR_SUFFIX;

export const GET_PAYMENTS_PREFIX = "GET_PAYMENTS";
export const GET_PAYMENTS_START = GET_PAYMENTS_PREFIX + START_SUFFIX;
export const GET_PAYMENTS_SUCCESS = GET_PAYMENTS_PREFIX + SUCCESS_SUFFIX;
export const GET_PAYMENTS_FAIL = GET_PAYMENTS_PREFIX + ERROR_SUFFIX;

export const EXPORT_PAYMENTS_PREFIX = "EXPORT_PAYMENTS";
export const EXPORT_PAYMENTS_START = EXPORT_PAYMENTS_PREFIX + START_SUFFIX;
export const EXPORT_PAYMENTS_SUCCESS = EXPORT_PAYMENTS_PREFIX + SUCCESS_SUFFIX;
export const EXPORT_PAYMENTS_FAIL = EXPORT_PAYMENTS_PREFIX + ERROR_SUFFIX;

export const SEND_PAYMENTS_PREFIX = "SEND_PAYMENTS";
export const SEND_PAYMENTS_START = SEND_PAYMENTS_PREFIX + START_SUFFIX;
export const SEND_PAYMENTS_SUCCESS = SEND_PAYMENTS_PREFIX + SUCCESS_SUFFIX;
export const SEND_PAYMENTS_FAIL = SEND_PAYMENTS_PREFIX + ERROR_SUFFIX;

export const GET_REPORT_PREFIX = "GET_REPORT";
export const GET_REPORT_START = GET_REPORT_PREFIX + START_SUFFIX;
export const GET_REPORT_SUCCESS = GET_REPORT_PREFIX + SUCCESS_SUFFIX;
export const GET_REPORT_FAIL = GET_REPORT_PREFIX + ERROR_SUFFIX;

export const SAVE_DATES_PREFIX = "SAVE_DATES";
export const SAVE_DATES_START = SAVE_DATES_PREFIX + START_SUFFIX;
export const SAVE_DATES_SUCCESS = SAVE_DATES_PREFIX + SUCCESS_SUFFIX;
export const SAVE_DATES_FAIL = SAVE_DATES_PREFIX + ERROR_SUFFIX;

export const GET_GEN_SETTINGS_PREFIX = "GET_GEN_SETTINGS";
export const GET_GEN_SETTINGS_START = GET_GEN_SETTINGS_PREFIX + START_SUFFIX;
export const GET_GEN_SETTINGS_SUCCESS =
  GET_GEN_SETTINGS_PREFIX + SUCCESS_SUFFIX;
export const GET_GEN_SETTINGS_FAIL = GET_GEN_SETTINGS_PREFIX + ERROR_SUFFIX;

export const SAVE_GEN_SETTINGS_PREFIX = "SAVE_GEN_SETTINGS";
export const SAVE_GEN_SETTINGS_START = SAVE_GEN_SETTINGS_PREFIX + START_SUFFIX;
export const SAVE_GEN_SETTINGS_SUCCESS =
  SAVE_GEN_SETTINGS_PREFIX + SUCCESS_SUFFIX;
export const SAVE_GEN_SETTINGS_FAIL = SAVE_GEN_SETTINGS_PREFIX + ERROR_SUFFIX;

export const PAYSTATS_RESET = "PAYSTATS_RESET";
export const PAYSTATS_LOAD_START = "PAYSTATS_LOAD_START";
export const PAYSTATS_LOAD_SUCCESS = "PAYSTATS_LOAD_SUCCESS";
export const TRIGGER_PRINT = "TRIGGER_PRINT";
export const TRIGGER_ZPRINT = "TRIGGER_ZPRINT";

export const GET_LIVE_UPDATES_PREFIX = "GET_LIVE_UPDATES";
export const GET_LIVE_UPDATES_START = GET_LIVE_UPDATES_PREFIX + START_SUFFIX;
export const GET_LIVE_UPDATES_SUCCESS =
  GET_LIVE_UPDATES_PREFIX + SUCCESS_SUFFIX;
export const GET_LIVE_UPDATES_FAIL = GET_LIVE_UPDATES_PREFIX + ERROR_SUFFIX;
