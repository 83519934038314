/* @flow */

import React, { Component } from 'react'

import { Alert } from '../StyledComponents/Main'


export default class StatusMessage extends Component<any,any> {

  render() {
    const { lang, object } = this.props

    return (
      <Alert type={object.get('type')}>
        <h3>{lang[object.get('message')]}</h3>
      </Alert>
    )
  }
}
