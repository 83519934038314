/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import { EDIT_LIB_PREFIX } from "../../Data/constants/actions";
import {
  getLang,
  getUI,
  getCanAdmin,
  getLibraries,
  getLibrary,
} from "../../Data/selectors";

import { Container, Body, Footer } from "../StyledComponents/Page";
import { MainArea } from "../StyledComponents/Main";
import MainSidebar from "../Components/MainSidebar";
import StatusMessage from "../Components/StatusMessage";
import { FormGroup, LoginForm, Btn } from "../StyledComponents/Forms";
import { editLibrary } from "../../Data/actionCreators";

export class LibraryEdit extends Component<any, any> {
  state = { name: "", dirty: false };

  componentDidMount = () => {
    this.setState({ dirty: false });
  };

  componentDidUpdate = (prevProps) => {
    const { libraries, match } = this.props;
    const { id } = match.params;
    const library = getLibrary(libraries, id);
    if (library && !this.state.dirty) {
      this.setState({ name: library.get("name"), dirty: true });
    }
  };

  change = (key: string) => (e: Event) => {
    this.setState({ [key]: e.target.value, dirty: true });
  };

  submit = (e: Event) => {
    const { dispatch, match } = this.props;
    const { id } = match.params;
    const { name } = this.state;
    dispatch(editLibrary(id, name));
  };

  render() {
    const { libraries, ui, lang, canAdmin } = this.props;
    const { name } = this.state;

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            canAdmin={canAdmin}
          ></MainSidebar>
          <MainArea>
            {ui.has(EDIT_LIB_PREFIX) ? (
              <StatusMessage lang={lang} object={ui.get(EDIT_LIB_PREFIX)} />
            ) : null}
            <LoginForm>
              <h1>{lang.editLibTitle}</h1>
              <FormGroup>
                <label htmlFor="name">{lang.libName}</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={this.change("name")}
                />
              </FormGroup>
              <Btn onClick={this.submit}>{lang.edit}</Btn>
            </LoginForm>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(LibraryEdit);
