/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import Gauge from "react-svg-gauge";
import { Row, Col } from "../StyledComponents/Grid";
import BarStatsChart from "../Components/BarStatsChart";
import Updating from "../Components/Updating";
import moment from "moment";
import { Link } from "react-router-dom";

import {
  getUI,
  getCanAdmin,
  getLang,
  getInfo,
  getLibraries,
  getMisc,
} from "../../Data/selectors";
import {
  startGetMainStats,
  startGetLiveUpdates,
  startUpdate,
  startLogout,
} from "../../Data/actionCreators";

import { Container, Body, Footer } from "../StyledComponents/Page";
import {
  MainArea,
  CenterPage,
  StatusTxt,
  ClickableTxt,
  Alert,
  StatusTable,
} from "../StyledComponents/Main";
import MainSidebar from "../Components/MainSidebar";
import red_cross from "../res/img/red_cross.svg";
import { List } from "immutable";
import { AutoComplete } from "antd";

export class Main extends Component<any, any> {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(startGetMainStats());
    dispatch(startGetLiveUpdates());
  }

  update = () => {
    const { lang, dispatch } = this.props;
    const res = window.confirm(lang.confirmUpdate);
    if (res) dispatch(startUpdate());
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  gotoSelfcheck = (value) => {
    window.location.href = "/#/selfchecks/" + value;
  };

  filterSelfcheck = (value, option) => {
    return (
      option.props.children.toUpperCase().indexOf(value.toUpperCase()) !== -1
    );
  };

  render() {
    const {
      ui,
      libraries,
      info,
      lang,
      stats,
      canAdmin,
      liveUpdates,
    } = this.props;

    const isUpdating = ui.get("updating", false);

    const selfChecks = libraries
      .map((e) => e.get("selfChecks"))
      .valueSeq()
      .toList()
      .flatten(1);
    const connected = selfChecks.filter((e) => e.get("status") === 1).count();
    const total = selfChecks.count();

    const yday =
      stats && stats.get("yesterday") ? stats.get("yesterday").toJS() : {};
    const week = stats && stats.get("week") ? stats.get("week").toJS() : {};

    const d = moment(info.expiring);
    const oneMonth = moment().add(1, "months");
    const showLicenseWarning = d.isBefore(oneMonth);
    const options = selfChecks
      .map((selfCheck) => ({
        value: selfCheck.get("id"),
        text:
          libraries.get(selfCheck.get("library_id")).get("name") +
          " - " +
          selfCheck.get("name"),
      }))
      .toJS();

    return (
      <Container>
        {isUpdating && <Updating lang={lang} />}
        <Body>
          <MainSidebar
            home={true}
            lang={lang}
            libraries={libraries}
            canAdmin={canAdmin}
            logout={this.logout}
          />
          <MainArea>
            {info.expiring && showLicenseWarning && (
              <Alert type="error">
                <h3>{lang.expiringSoon(info.expiring)}</h3>
              </Alert>
            )}
            <CenterPage>
              <AutoComplete
                size="large"
                style={{ width: "100%", marginTop: 15 }}
                placeholder="Search selfchecks"
                dataSource={options}
                onSelect={this.gotoSelfcheck}
                filterOption={this.filterSelfcheck}
              ></AutoComplete>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {info.version ? (
                  <h4 style={{ marginTop: 0, marginLeft: "auto" }}>
                    {lang.versionTxt} {info.version}
                  </h4>
                ) : null}
                <h1 style={{ marginTop: -30 }}>Welcome to ManageIT</h1>
              </div>
              {canAdmin && info.newVersion ? (
                <StatusTxt style={{ margin: 0 }}>
                  {lang.newVersionTxt} {info.newVersion}{" "}
                  <ClickableTxt onClick={this.update}>
                    {lang.update}
                  </ClickableTxt>
                </StatusTxt>
              ) : null}
              {connected && total && (
                <Gauge
                  value={connected}
                  max={total}
                  color="rgb(94, 147, 236)"
                  backgroundColor="rgb(255, 254, 135)"
                  width={300}
                  height={200}
                  label="Connected selfchecks"
                  topLabelStyle={{ fontSize: 18 }}
                />
              )}
              <Row style={{ marginTop: 50 }}>
                <Col size={2} style={{ padding: "0 10%" }}>
                  <BarStatsChart lang={lang} {...yday} />
                  Yesterday
                </Col>
                <Col size={2} style={{ padding: "0 10%" }}>
                  <BarStatsChart lang={lang} {...week} />
                  Last 7 days
                </Col>
              </Row>
            </CenterPage>

            <Row bb={true}>
              <Col size={2} bold={true} center={true}>
                {lang.device}
              </Col>
              <Col size={2} bold={true} center={true}>
                {lang.status}
              </Col>
              <Col size={4} bold={true} center={true}>
                {lang.statusMsg}
              </Col>
            </Row>
            <StatusTable>
              {libraries
                .map((library) =>
                  library
                    .get("selfChecks")
                    .map((selfCheck) => {
                      const status = selfCheck.get("status");
                      if (status === 1) return null;

                      return (
                        <Row bb={true} key={selfCheck.get("id")}>
                          <Col size={2} center={true}>
                            <Link to={"/selfchecks/" + selfCheck.get("id")}>
                              {library.get("name")} - {selfCheck.get("name")}
                            </Link>
                          </Col>
                          <Col size={2} center={true}>
                            <img alt="" src={red_cross} width={45} />
                          </Col>
                          <Col size={4} center={true}>
                            {selfCheck.get("message")}
                          </Col>
                        </Row>
                      );
                    })
                    .filter((e) => e)
                )
                .valueSeq()
                .toList()
                .flatten(1)}
            </StatusTable>
            {liveUpdates.size > 0 && (
              <center style={{ marginTop: 30 }}>
                <h2>Live Updates</h2>
                <textarea
                  style={{ width: "100%" }}
                  readOnly
                  rows="10"
                  value={liveUpdates
                    .map((e) => e.get("text", ""))
                    .join("\n\n\n")}
                ></textarea>
              </center>
            )}
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  ui: getUI(state),
  stats: getMisc(state, "main-stats"),
  liveUpdates: getMisc(state, "liveUpdates") || new List(),
  canAdmin: getCanAdmin(state),
  info: getInfo(state),
  libraries: getLibraries(state),
  lang: getLang(state),
}))(Main);
