// const btnProp = [
//   { id: "text", type: "text" },
//   { id: "fontSize", type: "number" },
//   { id: "foreground", type: "color" },
//   { id: "weight", type: "weight" },
//   { id: "horizontalAlign", type: "halign" },
//   { id: "verticalAlign", type: "valign" },
//   { id: "visible", type: "checkbox" },
// ];

const btnVisibleOnly = [{ id: "visible", type: "checkbox" }];
const btnTextOnly = [{ id: "text", type: "text" }];
const btnTextAndVisibleOnly = [{ id: "text", type: "text" }];

const subWelcome = [
  {
    id: "buttons",
    elements: [
      {
        id: "borrow",
        properties: btnVisibleOnly,
      },
      {
        id: "return",
        properties: btnVisibleOnly,
      },
      {
        id: "renew",
        properties: btnVisibleOnly,
      },
      {
        id: "pay",
        properties: btnVisibleOnly,
      },
      {
        id: "reservation",
        properties: btnVisibleOnly,
      },
      {
        id: "thirdParty",
        properties: btnVisibleOnly,
      },
    ],
  },
  {
    id: "headings",
    elements: [
      {
        id: "top",
        properties: btnTextAndVisibleOnly,
      },
    ],
  },
  {
    id: "images",
    elements: [
      {
        id: "images",
        properties: [
          { id: "headingImg", type: "image" },
          { id: "rightImg", type: "image" },
          { id: "leftImg", type: "image" },
          // { id: "helpBtn", type: "image" },
          { id: "issueBtn", type: "image" },
          { id: "returnBtn", type: "image" },
          { id: "renewBtn", type: "image" },
          { id: "payBtn", type: "image" },
          { id: "reservationBtn", type: "image" },
          { id: "thirdPartyBtn", type: "image" },
        ],
      },
    ],
  },
];

const subLogin = [
  {
    id: "buttons",
    elements: [
      {
        id: "helpBtn",
        properties: btnVisibleOnly,
      },
      {
        id: "backBtn",
        properties: btnVisibleOnly,
      },
      {
        id: "keypadBtn",
        properties: btnVisibleOnly,
      },
    ],
  },
  {
    id: "headings",
    elements: [
      {
        id: "top",
        properties: btnTextAndVisibleOnly,
      },
    ],
  },
  // {
  //   id: "images",
  //   elements: [
  //     {
  //       id: "images",
  //       properties: [
  //         { id: "helpBtn", type: "image" },
  //         { id: "backBtn", type: "image" },
  //         { id: "keypadBtn", type: "image" },
  //       ],
  //     },
  //   ],
  // },
];

const subBorrow = [
  // {
  //   id: "buttons",
  //   elements: [
  //     {
  //       id: "helpBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "returnBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "renewBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "payBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "reservationBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "printReceiptBtn",
  //       properties: btnProp,
  //     },
  //   ],
  // },
  {
    id: "headings",
    elements: [
      {
        id: "top",
        properties: btnTextOnly,
      },
      // {
      //   id: "table",
      //   properties: [
      //     { id: "statusVisible", type: "checkbox" },
      //     { id: "payVisible", type: "checkbox" },
      //   ],
      // },
    ],
  },
  // {
  //   id: "images",
  //   elements: [
  //     {
  //       id: "images",
  //       properties: [
  //         { id: "helpBtn", type: "image" },
  //         { id: "returnBtn", type: "image" },
  //         { id: "renewBtn", type: "image" },
  //         { id: "payBtn", type: "image" },
  //         { id: "reservationBtn", type: "image" },
  //         { id: "printReceiptBtn", type: "image" },
  //       ],
  //     },
  //   ],
  // },
];

const subPay = [
  // {
  //   id: "buttons",
  //   elements: [
  //     {
  //       id: "helpBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "returnBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "renewBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "payBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "reservationBtn",
  //       properties: btnProp,
  //     },
  //     {
  //       id: "printReceiptBtn",
  //       properties: btnProp,
  //     },
  //   ],
  // },
  {
    id: "headings",
    elements: [
      {
        id: "top",
        properties: btnTextOnly,
      },
    ],
  },
  // {
  //   id: "images",
  //   elements: [
  //     {
  //       id: "images",
  //       properties: [
  //         { id: "helpBtn", type: "image" },
  //         { id: "returnBtn", type: "image" },
  //         { id: "renewBtn", type: "image" },
  //         { id: "payBtn", type: "image" },
  //         { id: "reservationBtn", type: "image" },
  //         { id: "printReceiptBtn", type: "image" },
  //         { id: "cardBtn", type: "image" },
  //       ],
  //     },
  //   ],
  // },
];

const themeSchema = [
  { id: "welcome", sub: subWelcome },
  { id: "login", sub: subLogin },
  { id: "borrow", sub: subBorrow },
  { id: "return", sub: subBorrow },
  { id: "renew", sub: subBorrow },
  { id: "reservation", sub: subBorrow },
  { id: "pay", sub: subPay },
];

export default themeSchema;
