/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import keys from "lodash/keys";
import mapValues from "lodash/mapValues";
import flatten from "lodash/flatten";
import values from "lodash/values";
import keyBy from "lodash/keyBy";
import { fromJS, Map } from "immutable";
import { SAVE_DATES_PREFIX } from "../../Data/constants/actions";

import {
  startLogout,
  startGetReport,
  startSaveDates,
  uiUnset,
  triggerZPrint,
} from "../../Data/actionCreators";
import {
  getUI,
  getCanAdmin,
  getLang,
  getLibraries,
  getReport,
} from "../../Data/selectors";

import FA from "react-fontawesome";
import { Container, Body, Footer } from "../StyledComponents/Page";
import { RowG, ColG } from "../StyledComponents/Grid";
import { MainArea, SelectIds, SelectId } from "../StyledComponents/Main";
import { SettingsTitle } from "../StyledComponents/Settings";
import MainSidebar from "../Components/MainSidebar";
import PaymentSegment from "../Components/PaymentSegment";
import { Btn } from "../StyledComponents/Forms";
import StatusMessage from "../Components/StatusMessage";

export class Payments extends Component<any, any> {
  state = {
    ids: {},
    selected: { payment: true, council: true, combined: true },
  };

  load = () => {
    const { ids } = this.state;
    const { dispatch } = this.props;
    const _ids = keys(pickBy(ids, identity));
    dispatch(uiUnset({ path: [SAVE_DATES_PREFIX] }));
    dispatch(startGetReport(_ids));
  };

  saveDate = () => {
    const { ids } = this.state;
    const { dispatch } = this.props;
    const _ids = keys(pickBy(ids, identity));
    dispatch(uiUnset({ path: [SAVE_DATES_PREFIX] }));
    dispatch(startSaveDates(_ids));
  };

  select = (id: any, library: any, isLib: boolean) => (e: Event) => {
    const { ids } = this.state;
    if (isLib) {
      const newIds = library.get("selfChecks").reduce((p, selfCheck) => {
        p[selfCheck.get("id")] = e.target.checked;
        return p;
      }, {});
      const newIds2 = { ...ids, ...newIds, [id]: e.target.checked };
      this.setState({ ids: newIds2 });
    } else {
      const libId = library.get("id");
      const libCheckStatus = e.target.checked ? ids[libId] : false;
      const newIds = {
        ...ids,
        [id]: e.target.checked,
        [libId]: libCheckStatus,
      };
      this.setState({ ids: newIds });
    }
  };

  sumRows = (rows: IList) => {
    return rows.reduce((p, v) => {
      return fromJS({
        type: "combined",
        count: p.get("count", 0) + v.get("count", 0),
        cash: p.get("cash", 0) + v.get("cash", 0),
        change: p.get("change", 0) + v.get("change", 0),
        card: p.get("card", 0) + v.get("card", 0),
        ffloat: p.get("ffloat", 0) + v.get("ffloat", 0),
        notes: p.get("notes", 0) + v.get("notes", 0),
      });
    }, fromJS({}));
  };

  displayPrint = (lang: Object, row: IMap) => {
    return (
      row.get("type") && {
        title: {
          payment: lang.libraryPayments,
          council: lang.councilPayments,
          combined: lang.combinedReport,
        }[row.get("type")],
        totalTX: lang.totalTX + ": " + row.get("count", 0),
        cashPaidIn: lang.cashPaidIn + ": " + row.get("cash", 0).toFixed(2),
        cardPaidIn: lang.cardPaidIn + ": " + row.get("card", 0).toFixed(2),
        float: lang.float + ": " + row.get("ffloat", 0).toFixed(2),
        notes: lang.notes + ": " + row.get("notes", 0).toFixed(2),
        cashChangeGiven:
          lang.cashChangeGiven + ": " + row.get("change", 0).toFixed(2),
        overallCash:
          lang.overallCash +
          ": " +
          (row.get("cash", 0) - row.get("change", 0)).toFixed(2),
      }
    );
  };

  print = () => {
    const { dispatch, libraries, report, lang } = this.props;
    const { selected } = this.state;

    const librariesJS = libraries.toJS();
    const libraryNames = mapValues(librariesJS, (x) => x.name);
    const libraryKiosks = keyBy(
      flatten(values(mapValues(librariesJS, (x) => x.selfChecks))),
      "id"
    );

    const data = report.entrySeq().map(([id, rows]) => {
      const kiosk = libraryKiosks[id];
      const libName = kiosk ? libraryNames[kiosk.library_id] : "";
      const kioskName = kiosk ? kiosk.name : "";
      const since = kiosk ? kiosk.zdate : "";
      const _rows = Map(rows.map((v) => [v.get("type"), v]));
      const combined = this.sumRows([
        _rows.get("council", Map()),
        _rows.get("payment", Map()),
      ]);
      const all = _rows.set("combined", combined);

      return {
        since,
        libName: libName ? lang.libName + ": " + libName : "",
        kioskId: id ? lang.kioskId + ": " + id : "",
        kioskName: kioskName ? lang.kioskName + ": " + kioskName : "",
        payment: selected.payment
          ? this.displayPrint(
              lang,
              all.get("payment", fromJS({ type: "payment" }))
            )
          : null,
        council: selected.council
          ? this.displayPrint(
              lang,
              all.get("council", fromJS({ type: "council" }))
            )
          : null,
        combined: selected.combined
          ? this.displayPrint(
              lang,
              all.get("combined", fromJS({ type: "combined" }))
            )
          : null,
      };
    });

    const printData = data.toJS();

    dispatch(triggerZPrint(printData));
  };

  onSelectChange = (key: string) => () => {
    const { selected } = this.state;
    this.setState({ selected: { ...selected, [key]: !selected[key] } });
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(startLogout());
  };

  render() {
    const { ui, libraries, canAdmin, lang, report } = this.props;
    const { ids, selected } = this.state;
    const librariesJS = libraries.toJS();
    const libraryKiosks = keyBy(
      flatten(values(mapValues(librariesJS, (x) => x.selfChecks))),
      "id"
    );

    return (
      <Container>
        <Body>
          <MainSidebar
            lang={lang}
            libraries={libraries}
            canAdmin={canAdmin}
            logout={this.logout}
          />
          <MainArea>
            <SettingsTitle>{lang.zreport}</SettingsTitle>
            <RowG>
              <ColG size={3}>
                <SelectIds>
                  {libraries.valueSeq().map((library) => (
                    <React.Fragment key={library.get("id")}>
                      <SelectId>
                        <input
                          type="checkbox"
                          onChange={this.select(
                            library.get("id"),
                            library,
                            true
                          )}
                          checked={!!ids[library.get("id")]}
                        />{" "}
                        <b>{library.get("name")}</b>
                      </SelectId>
                      {library.get("selfChecks").map((selfCheck) => (
                        <SelectId indent={true} key={selfCheck.get("id")}>
                          <input
                            type="checkbox"
                            onChange={this.select(
                              selfCheck.get("id"),
                              library,
                              false
                            )}
                            checked={!!ids[selfCheck.get("id")]}
                          />{" "}
                          {selfCheck.get("name")}
                        </SelectId>
                      ))}
                    </React.Fragment>
                  ))}
                </SelectIds>
              </ColG>
              <ColG size={9}>
                {report.entrySeq().map(([k, e]) => (
                  <PaymentSegment
                    key={k}
                    name="combinedReport"
                    extraName={libraryKiosks[k].name}
                    lang={lang}
                    payments={e.reduce((p, v) => {
                      return fromJS({
                        cash: p.get("cash", 0) + v.get("cash", 0),
                        change: p.get("change", 0) + v.get("change", 0),
                        card: p.get("card", 0) + v.get("card", 0),
                        ffloat: p.get("ffloat", 0) + v.get("ffloat", 0),
                        notes: p.get("notes", 0) + v.get("notes", 0),
                      });
                    }, fromJS({}))}
                  />
                ))}
                {ui.has(SAVE_DATES_PREFIX) ? (
                  <StatusMessage
                    lang={lang}
                    object={ui.get(SAVE_DATES_PREFIX)}
                  />
                ) : null}
                {report.count() > 0 && (
                  <React.Fragment>
                    <h3 style={{ marginBottom: 5 }}>Include</h3>
                    <RowG
                      style={{ flexShrink: 0, marginBottom: 10, marginTop: 10 }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          onChange={this.onSelectChange("payment")}
                          checked={selected.payment}
                        />{" "}
                        Library Payments
                      </label>
                      <label style={{ marginLeft: 20 }}>
                        <input
                          type="checkbox"
                          onChange={this.onSelectChange("council")}
                          checked={selected.council}
                        />{" "}
                        Council Payments
                      </label>
                      <label style={{ marginLeft: 20 }}>
                        <input
                          type="checkbox"
                          onChange={this.onSelectChange("combined")}
                          checked={selected.combined}
                        />{" "}
                        Combined Payments
                      </label>
                    </RowG>
                  </React.Fragment>
                )}
                <RowG
                  style={{ flexShrink: 0, marginBottom: 10, marginTop: 10 }}
                >
                  <Btn
                    primary={true}
                    style={{ marginRight: 10 }}
                    onClick={this.load}
                  >
                    <FA name="table" /> {lang.load}
                  </Btn>
                  <Btn style={{ marginRight: 10 }} onClick={this.saveDate}>
                    <FA name="calendar" /> {lang.saveDate}
                  </Btn>
                  {report.count() > 0 && (
                    <Btn onClick={this.print}>
                      <FA name="print" /> {lang.print}
                    </Btn>
                  )}
                </RowG>
              </ColG>
            </RowG>
          </MainArea>
        </Body>
        <Footer />
      </Container>
    );
  }
}

export default connect((state: State) => ({
  ui: getUI(state),
  canAdmin: getCanAdmin(state),
  libraries: getLibraries(state),
  lang: getLang(state),
  report: getReport(state),
}))(Payments);
