/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import FA from 'react-fontawesome'
import { fromJS } from 'immutable'
import find from 'lodash/find'

import { getUI, getCanAdmin, getLibraries, getLanguages, getLang } from '../../Data/selectors'
import { startGetLanguages, setLanguages, startSaveLanguages, startLogout } from '../../Data/actionCreators'
import { SAVE_LANGUAGES_PREFIX } from '../../Data/constants/actions'

import { Container, Body, Footer } from '../StyledComponents/Page'
import { MainArea, CenterPage, ErrorTxt, StatusTable } from '../StyledComponents/Main'
import { Row, Col, ColL } from '../StyledComponents/Grid'
import { Btn } from '../StyledComponents/Forms'
import { SettingsHr } from '../StyledComponents/Settings'
import StatusMessage from '../Components/StatusMessage'
import MainSidebar from '../Components/MainSidebar'
import SelectInput from '../Components/Inputs/SelectInput'
import TextInput from '../Components/Inputs/TextInput'


export class Languages extends Component<any,any> {

  state = { newLang: fromJS({culture: '', friendlyName: ''}) }

  componentWillMount() {
    const { dispatch } = this.props
    dispatch(startGetLanguages())
  }

  getCultureName = (lang: Object, locale: string) => {
    const obj = find(lang.locales, x => x.value === locale)
    return obj.name
  }

  reload = () => window.location.reload()

  onNewLangChange = (path: Array<string>) => (value: any) => {
    const { newLang } = this.state
    const newNewLang = newLang.setIn(path, value)
    this.setState({newLang: newNewLang})
  }

  addLang = () => {
    const { languages, dispatch } = this.props
    const { newLang } = this.state
    if (newLang.get('culture') && newLang.get('friendlyName')) {
      const newLanguages = languages.push(newLang)
      dispatch(setLanguages(newLanguages))
      this.setState({newLang: fromJS({culture: '', friendlyName: ''})})
    }
  }

  deleteLang = (i: number) => () => {
    const { languages, dispatch } = this.props
    const newLanguages = languages.delete(i)
    dispatch(setLanguages(newLanguages))
  }

  saveLanguages = () => {
    const { dispatch, languages } = this.props
    dispatch(startSaveLanguages(languages))
  }

  logout = () => {
    const { dispatch } = this.props
    dispatch(startLogout())
  }

  render() {
    const { ui, languages, libraries, lang, canAdmin } = this.props

    return (
      ui.get('connectionError') ?
        <Container>
          <CenterPage>
            <ErrorTxt>
              <h1>{ lang.connectionError }</h1>
            </ErrorTxt>
            <Btn onClick={this.reload}><FA name='refresh' /> { lang.retry }</Btn>
          </CenterPage>
        </Container>
      :
      <Container>
      <Body>
        <MainSidebar lang={lang} libraries={libraries} id={null}
          canAdmin={canAdmin} logout={this.logout}></MainSidebar>
        <MainArea>
          {ui.has(SAVE_LANGUAGES_PREFIX) ?
            <StatusMessage lang={lang} object={ui.get(SAVE_LANGUAGES_PREFIX)} /> : null}
          <Row>
            <Col size={8}></Col>
            <Col size={2}>
              <Btn onClick={this.saveLanguages}><FA name='save' /> {lang.save}</Btn>
            </Col>
          </Row>
          <h1 style={{textAlign: 'center'}}>{lang.langs}</h1>
          <Row bb={true}>
            <Col size={2} bold={true} center={true}>{lang.culture}</Col>
            <Col size={4} bold={true} center={true}>{lang.langName}</Col>
            <Col size={2} bold={true} center={true}>{lang.delete}</Col>
          </Row>
          <StatusTable>
          {languages.map((language, i) =>
              <Row key={language.get('culture')} tiny={true}>
                <Col size={2} center={true}>
                  { this.getCultureName(lang, language.get('culture')) }
                </Col>
                <Col size={4} center={true}>
                  { language.get('friendlyName') }
                </Col>
                <Col size={2} center={true}>
                  <Btn onClick={this.deleteLang(i)} icon={1} danger={1} tiny={1}><FA name='times' /></Btn>
                </Col>
              </Row>
          )}
          </StatusTable>
          <SettingsHr />
          <Row>
            <Col size={2} />
            <ColL size={2}>{lang.culture}</ColL>
            <Col size={2}>
              <SelectInput optionCollection={true} options={lang.locales} path={['culture']} obj={this.state.newLang} onChange={this.onNewLangChange} />
            </Col>
            <Col size={2} />
          </Row>
          <Row>
            <Col size={2} />
            <ColL size={2}>{lang.friendlyName}</ColL>
            <Col size={2}>
              <TextInput type='text' path={['friendlyName']} obj={this.state.newLang} onChange={this.onNewLangChange} />
            </Col>
            <Col size={2} />
          </Row>
          <Row>
            <Col size={2} />
            <Col size={2}><Btn onClick={this.addLang}><FA name='globe' /> {lang.addLang}</Btn></Col>
            <Col size={2} />
          </Row>
        </MainArea>
      </Body>
      <Footer />
      </Container>
    )
  }
}

export default connect((state: State) => ({
  canAdmin: getCanAdmin(state),
  ui: getUI(state),
  libraries: getLibraries(state),
  languages: getLanguages(state),
  lang: getLang(state),
}))(Languages)
