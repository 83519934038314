/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import {
  Sidebar,
  SidebarItem,
  SidebarBottomItem,
  SidebarScroll,
  Status,
} from "../StyledComponents/Main";
import FA from "react-fontawesome";
import { getInfo } from "../../Data/selectors";

class MainSidebar extends Component<any, any> {
  componentDidMount = () => {
    if (window.__libsScrollTop__ && this.libsScroll) {
      this.libsScroll.scrollTop = window.__libsScrollTop__;
    }
  };

  onScrollLibs = (e) => {
    window.__libsScrollTop__ = e.target && e.target.scrollTop;
  };

  render() {
    const { libraries, home, lang, id, canAdmin, logout, info } = this.props;

    return (
      <Sidebar>
        {home ? (
          <Link to="/">
            <SidebarItem main={true}>
              <h2 style={{ margin: "auto", opacity: 0.65 }}>i16 Admin</h2>
            </SidebarItem>
          </Link>
        ) : (
          <Link to="/">
            <SidebarItem main={true}>
              <FA name="caret-left" size="2x" /> <h2>{lang.settings.back}</h2>
            </SidebarItem>
          </Link>
        )}
        <h3>{lang.libLocations}</h3>
        <SidebarScroll
          onScroll={this.onScrollLibs}
          innerRef={(el) => (this.libsScroll = el)}
        >
          {libraries.valueSeq().map((library) => (
            <React.Fragment key={library.get("id")}>
              <Link to={"/libraries/" + library.get("id")}>
                <SidebarItem active={library.get("id") === id}>
                  <Status small={true} status={library.get("status")} />{" "}
                  {library.get("name")}
                </SidebarItem>
              </Link>
              {library.get("selfChecks").map((selfCheck) => (
                <Link
                  to={"/selfchecks/" + selfCheck.get("id")}
                  key={selfCheck.get("id")}
                >
                  <SidebarItem
                    indent={true}
                    active={selfCheck.get("id") === id}
                  >
                    <FA name="arrow-right" />
                    <Status
                      smaller={true}
                      status={selfCheck.get("status")}
                    />{" "}
                    {selfCheck.get("name")}
                  </SidebarItem>
                </Link>
              ))}
            </React.Fragment>
          ))}
        </SidebarScroll>
        {canAdmin && (
          <Link to={"/libraries/create"}>
            <SidebarItem>
              <FA
                name="plus"
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  marginLeft: 2,
                  fontSize: 20,
                }}
              />{" "}
              {lang.add}
            </SidebarItem>
          </Link>
        )}
        <SidebarBottomItem>
          <SidebarItem onClick={logout}>
            <FA
              name="sign-out"
              style={{
                width: 20,
                height: 20,
                marginRight: 10,
                marginLeft: 2,
                fontSize: 20,
              }}
            />{" "}
            {lang.logout}
          </SidebarItem>
          <Link to="/help">
            <SidebarItem>{lang.help}</SidebarItem>
          </Link>
          {canAdmin ? (
            <React.Fragment>
              <Link to="/languages">
                <SidebarItem>{lang.langs}</SidebarItem>
              </Link>
              <Link to="/users">
                <SidebarItem>{lang.users}</SidebarItem>
              </Link>
              <Link to="/notifications">
                <SidebarItem>{lang.notifications}</SidebarItem>
              </Link>
            </React.Fragment>
          ) : null}
          <Link to="/combined-stats">
            <SidebarItem>{lang.combinedStats}</SidebarItem>
          </Link>

          {info && info.features && info.features.council && (
            <Link to="/councilPayments">
              <SidebarItem>{lang.councilPayments}</SidebarItem>
            </Link>
          )}
          <Link to="/payments">
            <SidebarItem>{lang.libraryPayments}</SidebarItem>
          </Link>
          {info && info.features && info.features.zreport && (
            <Link to="/zreport">
              <SidebarItem>{lang.zreport}</SidebarItem>
            </Link>
          )}
        </SidebarBottomItem>
      </Sidebar>
    );
  }
}

export default connect((state: State) => ({
  info: getInfo(state),
}))(MainSidebar);
