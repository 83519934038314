/* @flow */

import { Map, fromJS } from "immutable";
import {
  GET_MAIN_STATS_SUCCESS,
  GET_TOOLTIPS_SUCCESS,
  GET_GEN_SETTINGS_SUCCESS,
  GET_LIVE_UPDATES_SUCCESS,
} from "../constants/actions";

export default (state: IMap = new Map(), action: Action) => {
  switch (action.type) {
    case GET_MAIN_STATS_SUCCESS: {
      const data = action.payload;
      return state.set("main-stats", fromJS(data));
    }

    case GET_TOOLTIPS_SUCCESS: {
      const data = action.payload;
      return state.set("tooltips", fromJS(data));
    }

    case GET_GEN_SETTINGS_SUCCESS: {
      const data = action.payload && action.payload.general_settings;
      return state.set("genSettings", fromJS(data));
    }

    case GET_LIVE_UPDATES_SUCCESS: {
      const data = action.payload && action.payload.liveupdates;
      return state.set("liveUpdates", fromJS(data));
    }

    default:
      return state;
  }
};
