import { takeLatest, all, select, put } from "redux-saga/effects";
import { delay } from "redux-saga";
import { getLang, getLibraries, getLibrary, getSelfCheck } from "../selectors";
import axios from "axios";
import {
  INIT,
  GET_TOOLTIPS_SUCCESS,
  EXPORT_COUNTS_SUCCESS,
  EXPORT_PAYMENTS_SUCCESS,
  UI_UNSET,
  SAVE_THEMES_PREFIX,
  SAVE_THEMES_SUCCESS,
  SAVE_SETTINGS_PREFIX,
  SAVE_SETTINGS_SUCCESS,
} from "../constants/actions";

const toCsv = (lines, filename) => {
  const csv = lines.map((line) => line.join(",")).join("\r\n");
  const csvContent = "data:text/csv;charset=utf-8," + csv;
  const data = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", filename);
  link.click();
};

function* exportCountsCSV(action) {
  const { counts } = action.payload;
  const lang = yield select(getLang);
  const header = [
    [
      lang.date,
      lang.issues,
      lang.returns,
      lang.renews,
      lang.failedIssues,
      lang.failedReturns,
      lang.failedRenews,
    ],
  ];
  const data = counts.map((e) => [
    e.date,
    e.counts.issues || 0,
    e.counts.returns || 0,
    e.counts.renews || 0,
    e.counts.failedIssues || 0,
    e.counts.failedReturns || 0,
    e.counts.failedRenews || 0,
  ]);
  toCsv([...header, ...data], "counts.csv");
}

function* exportPaymentsCSV(action) {
  const { payments, type } = action.payload;
  const lang = yield select(getLang);
  const libraries = yield select(getLibraries);
  const header = [
    [
      lang.created_at,
      lang.kioskName,
      lang.cash,
      lang.change,
      lang.card,
      lang.float,
    ],
  ];
  const data = payments.map((e) => {
    const selfCheck = getSelfCheck(libraries, e.selfcheck_id);
    const library = getLibrary(libraries, selfCheck.get("library_id"));
    return [
      '"' + e.created_at + '"',
      library.get("name") + " - " + selfCheck.get("name"),
      e.cash.toFixed(2),
      e.change.toFixed(2),
      e.card.toFixed(2),
      e.ffloat.toFixed(2),
    ];
  });
  toCsv([...header, ...data], "stats-" + type + ".csv");
}

function* clearMessage(action) {
  const { type } = action;
  if (type === SAVE_SETTINGS_SUCCESS) {
    yield delay(3000);
    yield put({ type: UI_UNSET, payload: { path: [SAVE_SETTINGS_PREFIX] } });
  }

  if (type === SAVE_THEMES_SUCCESS) {
    yield delay(3000);
    yield put({ type: UI_UNSET, payload: { path: [SAVE_THEMES_PREFIX] } });
  }
}

function* init() {
  try {
    let base;
    if (process.env.NODE_ENV === "production") {
      base = "https://d-techmanageit.com/api/";
    } else {
      base = "http://localhost:8000/api/";
    }
    const { data } = yield axios({
      method: "get",
      url: base + "tooltips",
    });
    if (data.success) {
      yield put({ type: GET_TOOLTIPS_SUCCESS, payload: data.results });
    }
  } catch (e) {
    console.error(e);
  }
}

function* saga() {
  yield all([
    takeLatest(INIT, init),
    takeLatest(EXPORT_COUNTS_SUCCESS, exportCountsCSV),
    takeLatest(EXPORT_PAYMENTS_SUCCESS, exportPaymentsCSV),
    takeLatest(SAVE_THEMES_SUCCESS, clearMessage),
    takeLatest(SAVE_SETTINGS_SUCCESS, clearMessage),
  ]);
}

export default saga;
